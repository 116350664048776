<script setup>
import { Form } from "@primevue/forms"
import { storeToRefs } from "pinia"
import DatePicker from "primevue/datepicker"
import Dialog from "primevue/dialog"
import FloatLabel from "primevue/floatlabel"
import { useToast } from "primevue/usetoast"

import { getFullDate, getIsoString } from "../../../../../../ui/static_src/ui/utils/date.js"
import { TOAST_DURATION } from "../../../../../../ui/static_src/ui/utils/toast.js"
import { useCalendarStore } from "../../../stores/calendar.js"

const toast = useToast()
const calendar = useCalendarStore()
const { focusedClosure, closureModalOpen } = storeToRefs(calendar)

const resolver = () => {
  const errors = { start_date: [], end_date: [] }
  if (!focusedClosure.value.start_date) errors.start_date.push({ type: "required" })
  if (!focusedClosure.value.end_date) errors.end_date.push({ type: "required" })
  return { errors }
}

const onFormSubmit = async({ valid }) => {
  if (valid) {
    if (focusedClosure.value?.id) {
      await calendar.updateClosure({
        ...focusedClosure.value,
        start_date: getIsoString(focusedClosure.value.start_date),
        end_date: getIsoString(focusedClosure.value.end_date),
      })
    } else {
      await calendar.addClosure({
        ...focusedClosure.value,
        start_date: getIsoString(focusedClosure.value.start_date),
        end_date: getIsoString(focusedClosure.value.end_date),
      })
    }
    toast.add({
      severity: "success",
      summary: "Fermeture enregistrée",
      detail: `La fermeture du ${getFullDate(focusedClosure.value.start_date)} au ${getFullDate(focusedClosure.value.end_date)} a été enregistrée`,
      life: TOAST_DURATION,
    })
  }
}
</script>

<template>
  <Dialog
    v-model:visible="closureModalOpen"
    :header="focusedClosure?.id ? 'Modifier la fermeture' : 'Prévoir une fermeture'"
    modal
  >
    <Form
      class="closure-form"
      :resolver="resolver"
      @submit="onFormSubmit"
    >
      <div class="closure-form-block">
        <FloatLabel variant="on">
          <DatePicker
            id="start_date"
            v-model="focusedClosure.start_date"
            name="start_date"
            style="width: 12.5rem;"
            date-format="dd/mm/yy"
          />
          <label for="start_date">Date de début</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <DatePicker
            id="end_date"
            v-model="focusedClosure.end_date"
            name="end_date"
            date-format="dd/mm/yy"
            style="width: 12.5rem;"
          />
          <label for="end_date">Date de fin</label>
        </FloatLabel>
      </div>
      <div class="closure-form-validate">
        <button
          class="closure-form-button"
          type="submit"
        >
          {{ focusedClosure?.id ? 'Modifier' : 'Ajouter' }}
        </button>
      </div>
    </Form>
  </Dialog>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.closure-form {
  display: flex;
  flex-direction: column;
}

.closure-form-block {
  display: flex;
  gap: 1rem;
  margin-top: .4rem;
}

.closure-form-validate {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.4rem;
}

.closure-form-button {
  @include body;
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: .4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--color-button-bg-submit-full-hover);
  }
}
</style>
