<script setup>
import { storeToRefs } from "pinia"
import { onMounted } from "vue"

import EmptyStaffList from "../components/staff/EmptyStaffList.vue"
import StaffTable from "../components/staff/StaffTable.vue"
import StaffToolbar from "../components/staff/StaffToolbar.vue"

import useStaffStore from "../stores/staff.js"

const staff = useStaffStore()
const { isListEmpty, loading } = storeToRefs(staff)

onMounted(staff.init)
</script>

<template>
  <div
    v-if="loading"
    class="staff-loading-data"
  >
    <i class="pi pi-spin pi-cog loading-icon" />
  </div>
  <template v-else-if="!isListEmpty">
    <StaffToolbar />
    <StaffTable />
  </template>
  <EmptyStaffList v-else />
</template>

<style lang="scss" scoped>
@import "../../../../ui/static_src/ui/base/mixins";

.staff-loading-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-icon {
  color: var(--color-text-primary);
  font-size: 2rem;
}
</style>
