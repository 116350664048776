<script setup>
import Dialog from "primevue/dialog"

import InsuranceForm from "../../../../../../insurances/static_src/insurances/components/_partials/InsuranceForm.vue"

import { useCalendarStore } from "../../../stores/calendar.js"

const calendarStore = useCalendarStore()

const handleCreateInsurance = () => {
  calendarStore.insuranceModalOpen = false
}

const handleKeyDown = (event) => {
  if (event.key === "Escape") {
    calendarStore.insuranceModalOpen = false
    event.preventDefault()
    event.stopPropagation()
  }
}
</script>

<template>
  <Dialog
    v-model:visible="calendarStore.insuranceModalOpen"
    header="Créer une assurance"
    @keydown="handleKeyDown"
  >
    <InsuranceForm :on-creation="handleCreateInsurance" />
  </Dialog>
</template>
