import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "car-details" }
const _hoisted_2 = {
  key: 0,
  class: "car-details-block-text -warning"
}
const _hoisted_3 = {
  key: 1,
  class: "car-details-block-text -warning"
}
const _hoisted_4 = {
  key: 2,
  class: "car-details-title"
}
const _hoisted_5 = {
  key: 3,
  class: "car-details-block"
}
const _hoisted_6 = { class: "car-details-block-text -work" }
const _hoisted_7 = { class: "car-details-block-text -work" }
const _hoisted_8 = { class: "car-details-block-text -work" }
const _hoisted_9 = { class: "car-details-title" }
const _hoisted_10 = { class: "car-details-block" }
const _hoisted_11 = { class: "car-details-block-text -work" }
const _hoisted_12 = { class: "car-details-block-text -work" }
const _hoisted_13 = { class: "car-details-block-text -work" }
const _hoisted_14 = { class: "car-details-title" }
const _hoisted_15 = { class: "car-details-block" }
const _hoisted_16 = { class: "car-details-block-text" }
const _hoisted_17 = {
  key: 0,
  class: "car-details-block-text"
}
const _hoisted_18 = {
  key: 1,
  class: "car-details-block-text"
}
const _hoisted_19 = {
  key: 2,
  class: "car-details-block-text"
}
const _hoisted_20 = {
  key: 4,
  class: "car-details-title"
}
const _hoisted_21 = {
  key: 5,
  class: "insurances-box"
}
const _hoisted_22 = {
  key: 6,
  class: "car-details-block-text"
}
const _hoisted_23 = {
  key: 7,
  class: "car-details-block-text"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.car.due_date)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["CalendarOff"], {
            size: "16",
            class: "car-details-icon-text-warning"
          }),
          _createElementVNode("span", null, _toDisplayString($setup.getFullDate(new Date($setup.car.due_date))), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.isEndValidSUpportDateOver)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", null, " Fin de validité de la prise en charge le " + _toDisplayString($setup.getFullDate(new Date($setup.car.end_valid_support_date))), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    ($props.object.type === 'work')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode($setup["ArrowBigRight"], { size: "24" }),
          _cache[1] || (_cache[1] = _createTextVNode(" Heures de travail reportées "))
        ]))
      : _createCommentVNode("v-if", true),
    ($props.object.type === 'work')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[2] || (_cache[2] = _createTextVNode(" Tôlerie : ")),
            _createElementVNode("span", null, _toDisplayString($props.object.work.sheet_metal_work_hours), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[3] || (_cache[3] = _createTextVNode(" Peinture : ")),
            _createElementVNode("span", null, _toDisplayString($props.object.work.painting_hours), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[4] || (_cache[4] = _createTextVNode(" Mécanique : ")),
            _createElementVNode("span", null, _toDisplayString($props.object.work.mechanic_hours), 1 /* TEXT */)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_9, [
      _createVNode($setup["Hammer"], { size: "24" }),
      _createTextVNode(" " + _toDisplayString($props.object.type === 'work' ? 'Heures de travail terminées' : 'Heures de travail'), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _cache[5] || (_cache[5] = _createTextVNode(" Tôlerie : ")),
        _createElementVNode("span", null, _toDisplayString($setup.sheetMetalWorkHours), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[6] || (_cache[6] = _createTextVNode(" Peinture : ")),
        _createElementVNode("span", null, _toDisplayString($setup.paintingHours), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[7] || (_cache[7] = _createTextVNode(" Mécanique : ")),
        _createElementVNode("span", null, _toDisplayString($setup.mechanicHours), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode($setup["Info"], { size: "24" }),
      _cache[8] || (_cache[8] = _createTextVNode(" Informations "))
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createVNode($setup["UserRound"], {
          size: "16",
          class: "car-details-icon-text"
        }),
        _createElementVNode("span", null, _toDisplayString($setup.car.first_name) + " " + _toDisplayString($setup.car.owner_last_name), 1 /* TEXT */)
      ]),
      ($setup.car.registration)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createVNode($setup["Folder"], {
              size: "16",
              class: "car-details-icon-text"
            }),
            _createElementVNode("span", null, _toDisplayString($setup.car.registration), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.car.owner_phone_number)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createVNode($setup["Phone"], {
              size: "16",
              class: "car-details-icon-text"
            }),
            _createElementVNode("span", null, _toDisplayString($setup.car.owner_phone_number), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.car.owner_phone_number_2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            _createVNode($setup["Phone"], {
              size: "16",
              class: "car-details-icon-text"
            }),
            _createElementVNode("span", null, _toDisplayString($setup.car.owner_phone_number_2), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    ($setup.car.insurances.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createVNode($setup["Umbrella"], { size: "24" }),
          _cache[9] || (_cache[9] = _createTextVNode(" Assurances "))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.car.insurances.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.car.insurances, (insurance) => {
            return (_openBlock(), _createElementBlock("div", {
              key: insurance.id,
              class: "insurance-box"
            }, _toDisplayString(insurance.name), 1 /* TEXT */))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.car.foreground_note)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createVNode($setup["StickyNote"], {
            size: "16",
            class: "car-details-icon-text"
          }),
          _createElementVNode("span", null, _toDisplayString($setup.car.foreground_note), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.car.background_note)
      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.car.background_note) = $event)),
            class: "form__textarea -calendar-car",
            rows: "5",
            readonly: ""
          }, null, 512 /* NEED_PATCH */), [
            [_vModelText, $setup.car.background_note]
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}