<script setup>
import { useDebounceFn } from "@vueuse/core"
import ConfirmDialog from "primevue/confirmdialog"
import Toast from "primevue/toast"
import { computed, nextTick, onMounted, ref, watch } from "vue"

import CalendarCar from "../components/calendar/CalendarCar.vue"
import CalendarToolBar from "../components/calendar/CalendarToolBar.vue"
import CalendarWeek from "../components/calendar/CalendarWeek.vue"
import CarInChargeDialog from "../components/calendar/dialogs/CarInCharge.vue"
import ClosureDialog from "../components/calendar/dialogs/Closure.vue"
import ConfirmCarDeletion from "../components/calendar/dialogs/ConfirmCarDeletion.vue"
import DelayedWorkDialog from "../components/calendar/dialogs/DelayedWork.vue"
import InsuranceDialog from "../components/calendar/dialogs/Insurance.vue"
import NotificationsDialog from "../components/calendar/dialogs/Notifications.vue"
import ReplacementDialog from "../components/calendar/dialogs/Replacement.vue"
import ReplacementWeekSuggestionsDialog from "../components/calendar/dialogs/ReplacementWeekSuggestions.vue"
import EscapeRescheduleMode from "../components/calendar/messages/EscapeRescheduleMode.vue"

import { getIsoString } from "../../../../ui/static_src/ui/utils/date.js"
import { useCalendarStore } from "../stores/calendar.js"
import { useCarStore } from "../stores/car.js"
import { getStateDate } from "../utils/cars.js"

const calendarStore = useCalendarStore()
const carStore = useCarStore()
const calendarRef = ref(null)
const loadingNext = ref(false)
const loadingBefore = ref(false)
const noScroll = ref(false)
const weeks = ref([])
const triggerScroll = ref(0)

const env = window.CALENDAR_ENV
const scrollThreshold = 10000

const scrollTopBehavior = useDebounceFn(() => {
  const currentScroll = calendarRef.value.scrollTop

  const firstMonday = weeks.value[0].monday ? new Date(weeks.value[0].monday) : new Date(weeks.value[1].monday)
  const firstMondayElement = weeks.value[0].monday ? document.getElementById(getIsoString(firstMonday)) : null
  const firstMondayElementHeight = firstMondayElement ? firstMondayElement.getBoundingClientRect().height : 0

  if (currentScroll < firstMondayElementHeight + scrollThreshold) {
    calendarRef.value.scrollTop = firstMondayElementHeight + scrollThreshold
  }
}, 50)

const handleScroll = () => {
  scrollTopBehavior()
  const scrollHeight = calendarRef.value.scrollHeight
  if (calendarRef.value.scrollTop > scrollHeight - scrollThreshold) {
    calendarRef.value.scrollTop = scrollHeight - scrollThreshold
  }
}

const handleBeforeWeekLoad = async() => {
  if (loadingBefore.value) return
  loadingBefore.value = true

  const firstMonday = weeks.value[0]?.monday ? new Date(weeks.value[0].monday) : new Date(weeks.value[1].monday)
  firstMonday.setDate(firstMonday.getDate() - 7)
  const newWeek = await calendarStore.loadWeekData(firstMonday)

  const oldScrollHeight = calendarRef.value.scrollHeight
  const oldScrollTop = calendarRef.value.scrollTop

  if (weeks.value[0]?.monday) {
    weeks.value = [{ ...newWeek, delayAnimation: true }, weeks.value[1], weeks.value[0], ...weeks.value.slice(2)]
  } else {
    weeks.value = [{ ...newWeek, delayAnimation: true }, weeks.value[0], ...weeks.value.slice(1)]
  }

  await nextTick()

  const newScrollHeight = calendarRef.value.scrollHeight
  const heightDifference = newScrollHeight - oldScrollHeight

  if (calendarRef.value.scrollTop < oldScrollTop + heightDifference) {
    calendarRef.value.scrollTop = oldScrollTop + heightDifference
  }

  // eslint-disable-next-line compat/compat
  await new Promise((resolve) => setTimeout(resolve, 700))

  loadingBefore.value = false

  if (calendarRef.value.scrollTop < scrollThreshold + heightDifference + 2000) {
    handleBeforeWeekLoad()
  }
}

const handleNextWeekLoad = async() => {
  if (loadingNext.value) return
  loadingNext.value = true
  const lastMonday = new Date(weeks.value[weeks.value.length - 2].monday)
  lastMonday.setDate(lastMonday.getDate() + 7)
  const newWeek = await calendarStore.loadWeekData(lastMonday)
  // eslint-disable-next-line compat/compat
  requestAnimationFrame(() => {
    weeks.value = [...weeks.value.slice(0, weeks.value.length - 1), newWeek, weeks.value[weeks.value.length - 1]]
  })
  // eslint-disable-next-line compat/compat
  await new Promise((resolve) => setTimeout(resolve, 700))
  await nextTick()

  loadingNext.value = false

  const scrollHeight = calendarRef.value.scrollHeight
  const currentScroll = calendarRef.value.scrollTop

  if (currentScroll > scrollHeight - scrollThreshold - 2000) {
    handleNextWeekLoad()
  }
}

const handleWeekLoad = async(key) => {
  if (key === "sentinel-top") {
    await handleBeforeWeekLoad()
  } else {
    await handleNextWeekLoad()
  }
}

const draggedObject = computed(() => {
  if (carStore.draggingCar) return { type: "car", car: carStore.draggingCar }
  if (carStore.draggingWork) return { type: "work", work: carStore.draggingWork }
  return null
})

const handleEscaprePress = (event) => {
  if (event.key === "Escape") {
    if (carStore.draggingCar) {
      calendarStore.addNewDraggingCarStateDate(carStore.draggingCar.id, getStateDate(carStore.draggingCar))
      carStore.draggingCar = null
    }
    if (carStore.draggingWork) {
      if (carStore.creatingDelayedWork) {
        carStore.creatingDelayedWork = false
        calendarStore.deleteDelayedWork(carStore.draggingWork)
      } else calendarStore.addNewDraggingWorkDate(carStore.draggingWork.id, carStore.draggingWork.date)
      carStore.draggingWork = null
    }
  }
}

const scrollToSearchResult = async() => {
  // eslint-disable-next-line compat/compat
  return await new Promise((resolve) => {
    const carId = window.location.search.split("&search=")[1]
    if (carId) {
      const carElement = document.getElementById(`car-${carId}`)
      if (carElement) {
        const elementRect = carElement.getBoundingClientRect()
        const containerRect = calendarRef.value.getBoundingClientRect()
        const offsetTop = elementRect.top - containerRect.top + calendarRef.value.scrollTop
        calendarRef.value.style.scrollBehavior = "smooth"
        calendarRef.value.scrollTop = Math.max(offsetTop - 400, scrollThreshold)
        calendarRef.value.style.scrollBehavior = "auto"
        setTimeout(() => {
          calendarRef.value.style.overflowY = "hidden"
          noScroll.value = true
          resolve()
        }, 700)
        return
      }
    }
    resolve()
  })
}

watch(triggerScroll, () => {
  handleScroll()
})

onMounted(async() => {
  loadingNext.value = true
  loadingBefore.value = true
  const currentWeek = await calendarStore.init(env)
  if (calendarStore.isHomepage) {
    weeks.value = [currentWeek]
    return
  }

  weeks.value = [{ key: "sentinel-top" }, currentWeek, { key: "sentinel-bottom" }]
  await nextTick()

  setTimeout(async() => {
    loadingNext.value = false
    loadingBefore.value = false
    await scrollToSearchResult()
    await handleBeforeWeekLoad()
    // eslint-disable-next-line compat/compat
    await handleNextWeekLoad()
    calendarRef.value.style.overflowY = "scroll"
    noScroll.value = false
  }, 500)

  calendarRef.value.scrollTop = scrollThreshold + 5
  calendarRef.value.addEventListener("scroll", handleScroll)
  document.addEventListener("keydown", handleEscaprePress)
})
</script>

<template>
  <div class="calendar-app">
    <CalendarToolBar />
    <div
      ref="calendarRef"
      class="calendar"
    >
      <Toast />
      <ConfirmDialog />
      <EscapeRescheduleMode v-if="carStore.draggingCar || carStore.draggingWork" />
      <NotificationsDialog v-if="!calendarStore.isHomepage" />
      <ConfirmCarDeletion />
      <CarInChargeDialog />
      <InsuranceDialog />
      <ReplacementDialog />
      <ClosureDialog />
      <DelayedWorkDialog />
      <ReplacementWeekSuggestionsDialog />
      <CalendarCar
        v-if="carStore.draggingCar"
        draggable
        :object="draggedObject"
      />
      <CalendarCar
        v-if="carStore.draggingWork"
        draggable
        :object="draggedObject"
      />
      <CalendarWeek
        v-for="weekData in weeks"
        :id="weekData?.monday ? getIsoString(weekData.monday) : weekData.key"
        :key="weekData?.monday ? getIsoString(weekData.monday) : weekData.key"
        :week-data="weekData"
        :padding-right="noScroll"
        @sentinel-intersected="handleWeekLoad"
      />
      <div
        v-if="weeks.length === 0"
        class="calendar-loading-data"
      >
        <i class="pi pi-spin pi-cog loading-icon" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calendar-app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.calendar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 0.5rem;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

.calendar-loading-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-icon {
  color: var(--color-text-primary);
  font-size: 2rem;
}
</style>
