import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "staff-loading-data"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("i", { class: "pi pi-spin pi-cog loading-icon" }, null, -1 /* HOISTED */)
      ])))
    : (!$setup.isListEmpty)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode($setup["StaffToolbar"]),
          _createVNode($setup["StaffTable"])
        ], 64 /* STABLE_FRAGMENT */))
      : (_openBlock(), _createBlock($setup["EmptyStaffList"], { key: 2 }))
}