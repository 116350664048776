<script setup>
import Dialog from "primevue/dialog"
import FloatLabel from "primevue/floatlabel"
import InputNumber from "primevue/inputnumber"
import { useToast } from "primevue/usetoast"
import { ref, watch } from "vue"

import { TOAST_DURATION } from "../../../../../../ui/static_src/ui/utils/toast.js"
import { useCalendarStore } from "../../../stores/calendar.js"
import { useCarStore } from "../../../stores/car.js"
import { getStateDate } from "../../../utils/cars.js"

const carStore = useCarStore()
const calendarStore = useCalendarStore()
const toast = useToast()

const sheetMetalWorkHours = ref(0)
const paintingHours = ref(0)
const mechanicHours = ref(0)

const handleValidate = async(event) => {
  if (carStore.updatingDelayedWork) {
    await calendarStore.updateDelayedWork({
      ...carStore.focusedWork,
      sheet_metal_work_hours: sheetMetalWorkHours.value,
      painting_hours: paintingHours.value,
      mechanic_hours: mechanicHours.value,
    })
    toast.add({
      severity: "success",
      summary: `${carStore.focusedWork.car.owner_last_name} | ${carStore.focusedWork.car.model}`,
      detail: "Les heures de travail reportées ont été modifiées",
      life: TOAST_DURATION,
    })
    carStore.updatingDelayedWork = false
    carStore.focusedWork = null
  }
  if (carStore.creatingDelayedWork) {
    const delayedWork = {
      car: carStore.focusedWork.car ? carStore.focusedWork.car : carStore.focusedCar,
      sheet_metal_work_hours: sheetMetalWorkHours.value,
      painting_hours: paintingHours.value,
      mechanic_hours: mechanicHours.value,
      is_last: true,
      company_pk: calendarStore.env.company_pk,
      date: carStore.focusedWork.car ? getStateDate(carStore.focusedWork.car) : getStateDate(carStore.focusedCar),
    }
    const work = await calendarStore.createDelayedWork(delayedWork)
    carStore.setDraggingWork(work, event)
  }
  carStore.delayedWorkModalOpen = false
}

const updateHoursValues = (newValue) => {
  if (newValue) {
    sheetMetalWorkHours.value = newValue.sheet_metal_work_hours || 0
    paintingHours.value = newValue.painting_hours || 0
    mechanicHours.value = newValue.mechanic_hours || 0
  }
}

watch(() => carStore.focusedWork, updateHoursValues)
watch(() => carStore.focusedCar, updateHoursValues)
</script>

<template>
  <Dialog
    v-model:visible="carStore.delayedWorkModalOpen"
    header="Reporter des heures de travail"
    modal
  >
    <div class="calendar-delayed-work">
      <FloatLabel variant="on">
        <InputNumber
          id="sheet_metal_work_hours"
          v-model="sheetMetalWorkHours"
          class="input-delayed-work"
          :min-fraction-digits="1"
          locale="en-US"
          style="min-width: 14rem;"
        />
        <label for="sheet_metal_work_hours">Tôlerie</label>
      </FloatLabel>
      <FloatLabel variant="on">
        <InputNumber
          id="painting_hours"
          v-model="paintingHours"
          class="input-delayed-work"
          :min-fraction-digits="1"
          locale="en-US"
          style="min-width: 14rem;"
        />
        <label for="painting_hours">Peinture</label>
      </FloatLabel>
      <FloatLabel variant="on">
        <InputNumber
          id="mechanic_hours"
          v-model="mechanicHours"
          class="input-delayed-work"
          :min-fraction-digits="1"
          locale="en-US"
          style="min-width: 14rem;"
        />
        <label for="mechanic_hours">Mécanique</label>
      </FloatLabel>
    </div>
    <div class="submit-container">
      <button
        class="submit"
        @click="handleValidate"
      >
        {{ carStore.creatingDelayedWork ? 'Reporter' : 'Modifier' }}
      </button>
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.calendar-delayed-work {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
  height: 4rem;
}

.input-delayed-work {
  background: var(--content-bg--color-lighter) !important;
}
</style>
