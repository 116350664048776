import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "calendar-app" }
const _hoisted_2 = {
  ref: "calendarRef",
  class: "calendar"
}
const _hoisted_3 = {
  key: 4,
  class: "calendar-loading-data"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["CalendarToolBar"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["Toast"]),
      _createVNode($setup["ConfirmDialog"]),
      ($setup.carStore.draggingCar || $setup.carStore.draggingWork)
        ? (_openBlock(), _createBlock($setup["EscapeRescheduleMode"], { key: 0 }))
        : _createCommentVNode("v-if", true),
      (!$setup.calendarStore.isHomepage)
        ? (_openBlock(), _createBlock($setup["NotificationsDialog"], { key: 1 }))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["ConfirmCarDeletion"]),
      _createVNode($setup["CarInChargeDialog"]),
      _createVNode($setup["InsuranceDialog"]),
      _createVNode($setup["ReplacementDialog"]),
      _createVNode($setup["ClosureDialog"]),
      _createVNode($setup["DelayedWorkDialog"]),
      _createVNode($setup["ReplacementWeekSuggestionsDialog"]),
      ($setup.carStore.draggingCar)
        ? (_openBlock(), _createBlock($setup["CalendarCar"], {
            key: 2,
            draggable: "",
            object: $setup.draggedObject
          }, null, 8 /* PROPS */, ["object"]))
        : _createCommentVNode("v-if", true),
      ($setup.carStore.draggingWork)
        ? (_openBlock(), _createBlock($setup["CalendarCar"], {
            key: 3,
            draggable: "",
            object: $setup.draggedObject
          }, null, 8 /* PROPS */, ["object"]))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.weeks, (weekData) => {
        return (_openBlock(), _createBlock($setup["CalendarWeek"], {
          id: weekData?.monday ? $setup.getIsoString(weekData.monday) : weekData.key,
          key: weekData?.monday ? $setup.getIsoString(weekData.monday) : weekData.key,
          "week-data": weekData,
          "padding-right": $setup.noScroll,
          onSentinelIntersected: $setup.handleWeekLoad
        }, null, 8 /* PROPS */, ["id", "week-data", "padding-right"]))
      }), 128 /* KEYED_FRAGMENT */)),
      ($setup.weeks.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
            _createElementVNode("i", { class: "pi pi-spin pi-cog loading-icon" }, null, -1 /* HOISTED */)
          ])))
        : _createCommentVNode("v-if", true)
    ], 512 /* NEED_PATCH */)
  ]))
}