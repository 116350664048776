import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.replacementWeekSuggestionsModalOpen,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => (($setup.replacementWeekSuggestionsModalOpen) = $event)),
    header: "Suggestions de remplacement avec l'IA",
    modal: "",
    onAfterHide: _cache[2] || (_cache[2] = $event => ($setup.suggestedReplacements = []))
  }, {
    default: _withCtx(() => [
      _createVNode($setup["WeekReplacementSuggestions"], {
        modelValue: $setup.suggestedReplacements,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.suggestedReplacements) = $event)),
        monday: $setup.replacementWeekSuggestionsMonday,
        "insurance-groups": $setup.insuranceGroups,
        "open-replacement-dialog": $setup.openReplacementDialog,
        "cars-in-charge": $setup.replacementWeekSuggestionsCarsInCharge.filter(car => !car.is_deleted && car.need_replacement && car.replacements.length === 0),
        onReplacementsCreated: $setup.handleCreatedReplacements
      }, null, 8 /* PROPS */, ["modelValue", "monday", "insurance-groups", "cars-in-charge"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}