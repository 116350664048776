<script setup>
import { orderBy } from "lodash-es"
import { Check, Pencil } from "lucide-vue-next"
import { storeToRefs } from "pinia"
import { Timeline } from "primevue"
import { computed } from "vue"

import { getFullDate } from "../../../../../../ui/static_src/ui/utils/date.js"
import useStaffStore from "../../../stores/staff.js"

const staff = useStaffStore()
const { staffObject } = storeToRefs(staff)

const isBeforeToday = (strDate) => {
  return new Date(strDate).getTime() < new Date().getTime()
}

const contractTypeNames = {
  cdi: "CDI",
  cdd: "CDD",
  freelance: "Indépendant",
}

const contractTimeline = computed(() => {
  const timeline = []
  staffObject.value.contract_set.forEach((contract) => {
    timeline.push({
      contractId: contract.id,
      date: new Date(contract.start_date),
      dateType: "start",
    })
    timeline.push({
      contractId: contract.id,
      date: contract.end_date ? new Date(contract.end_date) : null,
      text: `${contractTypeNames[contract.type]} - ${contract.weekly_hours}h par semaine`,
      dateType: "end",
    })
  })
  return orderBy(timeline, "date", "desc")
})
</script>

<template>
  <Timeline
    v-if="staffObject.contract_set.length > 0"
    :value="contractTimeline"
    class="staff-timeline-content"
  >
    <template #content="slotProps">
      <div
        v-if="slotProps.item.text"
        class="staff-timeline-contract"
      >
        {{ slotProps.item.text }}
        <button
          v-tooltip.top="{ value: 'Modifier le contrat', showDelay: 500 }"
          class="staff-timeline-edit"
          @click="staff.openContractModal(
            staffObject,
            staffObject.contract_set.find(c => c.id === slotProps.item.contractId)
          )"
        >
          <Pencil size="14" />
        </button>
      </div>
    </template>
    <template #opposite="slotProps">
      <div class="staff-timeline-date -flex-end">
        {{ slotProps.item.date ? getFullDate(slotProps.item.date) : '...' }}
      </div>
    </template>
    <template #marker="slotProps">
      <div
        v-if="slotProps.item.dateType === 'start' && isBeforeToday(slotProps.item.date)"
        class="staff-timeline-marker -start"
      >
        <Check
          size="14"
          class="staff-timeline-icon"
        />
      </div>
      <div
        v-else-if="slotProps.item.dateType === 'end' && slotProps.item.date && isBeforeToday(slotProps.item.date)"
        class="staff-timeline-marker -end"
      >
        <Check
          size="14"
          class="staff-timeline-icon"
        />
      </div>
      <div
        v-else
        class="staff-timeline-marker-date"
      />
    </template>
  </Timeline>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.staff-timeline-marker-date {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: baseline;
  border-width: 2px;
  border-style: solid;
  border-color: var(--color-text-secondary);
  border-radius: 999px;
  width: 18px;
  height: 18px;
}

.staff-timeline-marker {
  background-color: var(--color-button-bg-submit);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;

  &.-end {
    background-color: var(--color-text-success);
  }
}

.staff-timeline-icon {
  color: var(--color-text-quaternary);
}

.staff-timeline-contract,
.staff-timeline-date {
  @include body;
  display: flex;
  align-items: center;
  gap: .4rem;
  transform: translateY(-0.2rem);

  &.-flex-end {
    justify-content: flex-end;
  }
}

.staff-timeline-edit {
  padding: .4rem;
  color: var(--color-text-secondary);

  &:hover {
    color: var(--color-text-primary);
  }
}
</style>
