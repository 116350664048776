import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "replacement-container" }
const _hoisted_2 = { class: "replacement-car-selecter" }
const _hoisted_3 = { style: {"width":"15rem"} }
const _hoisted_4 = {
  class: "replacement-car-insurance-groups",
  style: {"width":"5rem","text-align":"center"}
}
const _hoisted_5 = {
  class: "replacement-car-valid",
  style: {"width":"10rem"}
}
const _hoisted_6 = {
  key: 0,
  class: "replacement-car-borrow-dates",
  style: {"width":"10rem"}
}
const _hoisted_7 = { class: "replacement-car-borrow-date" }
const _hoisted_8 = { class: "replacement-car-borrow-date" }
const _hoisted_9 = {
  class: "replacement-car-borrow-dates",
  style: {"width":"15rem"}
}
const _hoisted_10 = { class: "replacement-car-borrow-car" }
const _hoisted_11 = { class: "replacement-car-dates" }
const _hoisted_12 = {
  key: 1,
  class: "conflict-replacements"
}
const _hoisted_13 = { class: "conflict-message" }
const _hoisted_14 = { class: "conflict-replacement" }
const _hoisted_15 = {
  key: 2,
  class: "conflict-replacements"
}
const _hoisted_16 = { class: "conflict-message" }
const _hoisted_17 = { class: "replacement-car-form-validate" }
const _hoisted_18 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["FloatLabel"], { variant: "on" }, {
      default: _withCtx(() => [
        _createVNode($setup["AutoComplete"], {
          id: "car_replacement",
          ref: "autoCompleteRef",
          modelValue: $setup.query,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.query) = $event)),
          style: {"width":"30rem"},
          suggestions: $setup.replacementsSuggestions,
          "option-label": option => option.registration + ' - ' + option.brand + ' - ' + option.model,
          onInput: $setup.onInput,
          onFocus: $setup.onFocus,
          onItemSelect: $setup.handleSelect
        }, {
          header: _withCtx(() => _cache[5] || (_cache[5] = [
            _createElementVNode("div", { class: "replacement-car-header" }, [
              _createElementVNode("div", { style: {"width":"15rem"} }, " Véhicules "),
              _createElementVNode("div", { style: {"width":"5rem","text-align":"center"} }, " Groupes "),
              _createElementVNode("div", { style: {"width":"10rem","text-align":"center"} }, " Prise en charge "),
              _createElementVNode("div", { style: {"width":"10rem","text-align":"center"} }, " Indisponibilité "),
              _createElementVNode("div", { style: {"width":"15rem","text-align":"center"} }, " Raison ")
            ], -1 /* HOISTED */)
          ])),
          option: _withCtx(({ option }) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(option.registration) + " - " + _toDisplayString(option.brand) + " - " + _toDisplayString(option.model), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option.insurance_groups, (group, index) => {
                  return _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "car__card-state",
                    style: _normalizeStyle(`background-color: ${group.color}`)
                  }, null, 4 /* STYLE */)), [
                    [
                      _directive_tooltip,
                      group.name,
                      void 0,
                      { bottom: true }
                    ]
                  ])
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _createElementVNode("div", _hoisted_5, [
                (option.score === 1)
                  ? (_openBlock(), _createBlock($setup["Check"], {
                      key: 0,
                      size: "18",
                      class: "replacement-car-valid-icon"
                    }))
                  : (_openBlock(), _createBlock($setup["X"], {
                      key: 1,
                      size: "18",
                      class: "replacement-car-invalid-icon"
                    }))
              ]),
              (option.unavailable_since)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString($setup.getShortDate(new Date(option.unavailable_since))), 1 /* TEXT */),
                    _createVNode($setup["ArrowBigRight"], {
                      size: "18",
                      class: "replacement-car-borrow-icon"
                    }),
                    _createElementVNode("div", _hoisted_8, _toDisplayString($setup.getShortDate(new Date(option.unavailable_up))), 1 /* TEXT */)
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createTextVNode(_toDisplayString(option.unavailable_reason) + _toDisplayString(option.replacement_car_incharge ? ' par ' : '') + " ", 1 /* TEXT */),
                  (option.replacement_car_incharge)
                    ? (_openBlock(), _createBlock($setup["CarName"], {
                        key: 0,
                        "car-in-charge": option.replacement_car_incharge,
                        "only-owner-last-name": ""
                      }, null, 8 /* PROPS */, ["car-in-charge"]))
                    : _createCommentVNode("v-if", true)
                ])
              ])
            ])
          ]),
          empty: _withCtx(() => _cache[6] || (_cache[6] = [
            _createElementVNode("div", { class: "replacement-car-empty" }, " Aucun véhicule ne correspond à votre recherche ", -1 /* HOISTED */)
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "suggestions", "option-label"]),
        _cache[7] || (_cache[7] = _createElementVNode("label", { for: "car_replacement" }, "Véhicule de remplacement", -1 /* HOISTED */))
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_11, [
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["DatePicker"], {
            id: "replacement-start-date",
            "show-time": "",
            "model-value": $props.modelValue.start_date,
            style: {"width":"14.5rem"},
            "date-format": "dd/mm/yy",
            "hour-format": "24",
            "min-date": $props.minDate,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => $setup.updateModel('start_date', value))
          }, null, 8 /* PROPS */, ["model-value", "min-date"]),
          _cache[8] || (_cache[8] = _createElementVNode("label", { for: "replacement-start-date" }, "Début du prêt", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["DatePicker"], {
            id: "replacement-end-date",
            "show-time": "",
            "model-value": $props.modelValue.end_date,
            style: {"width":"14.5rem"},
            "min-date": $props.minDate,
            "date-format": "dd/mm/yy",
            "hour-format": "24",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = (value) => $setup.updateModel('end_date', value))
          }, null, 8 /* PROPS */, ["model-value", "min-date"]),
          _cache[9] || (_cache[9] = _createElementVNode("label", { for: "replacement-end-date" }, "Fin du prêt", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    ($props.modelValue.id)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "checkbox",
          onClick: _cache[3] || (_cache[3] = $event => ($setup.updateModel('finished', !$props.modelValue.finished), $event.stopPropagation(), $event.preventDefault()))
        }, [
          _createVNode($setup["Checkbox"], {
            "model-value": $props.modelValue.finished,
            "input-id": "finished",
            binary: true,
            class: "checkbox-replacement"
          }, null, 8 /* PROPS */, ["model-value"]),
          _cache[10] || (_cache[10] = _createElementVNode("label", {
            for: "finished",
            style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
          }, "Véchiule rendu", -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.conflictReplacements.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode($setup["TriangleAlert"], { size: "18" }),
            _createTextVNode(" Le véhicule de remplacement est emprunté par " + _toDisplayString($setup.conflictReplacements.length === 1 ? "un" : $setup.conflictReplacements.length) + " autre" + _toDisplayString($setup.conflictReplacements.length === 1 ? '':'s') + " client" + _toDisplayString($setup.conflictReplacements.length === 1 ? '':'s') + " aux mêmes dates ", 1 /* TEXT */)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.conflictReplacements, (conflictReplacement) => {
            return (_openBlock(), _createElementBlock("div", {
              key: conflictReplacement.id,
              class: "conflict-replacements-list"
            }, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode($setup["ReplacementTimeline"], {
                  replacement: conflictReplacement,
                  "with-time": "",
                  "with-owner": ""
                }, null, 8 /* PROPS */, ["replacement"])
              ])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.conflictMaintenanceTimes.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createVNode($setup["TriangleAlert"], { size: "18" }),
            _cache[11] || (_cache[11] = _createTextVNode(" Le véhicule de remplacement est en maintenance aux mêmes dates "))
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("button", {
        class: "replacement-car-form-button",
        disabled: !$props.modelValue.start_date || !$props.modelValue.end_date || !$props.modelValue.replacement_car || $setup.conflictReplacements.length > 0 || $setup.conflictMaintenanceTimes.length > 0,
        onClick: _cache[4] || (_cache[4] = (...args) => ($props.handleValidate && $props.handleValidate(...args)))
      }, " Valider ", 8 /* PROPS */, _hoisted_18)
    ])
  ]))
}