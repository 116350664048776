<script setup>
import { useToast } from "primevue"
import { computed, onMounted, ref } from "vue"

import CarName from "../_partials/CarName.vue"
import InsuranceGroups from "../_partials/InsuranceGroups.vue"
import SuggestedReplacement from "../_partials/SuggestedReplacement.vue"

import { getStateDate } from "../../../../../companies/static_src/companies/utils/cars.js"
import { getDateDayName, getIsoString, getNextFriday } from "../../../../../ui/static_src/ui/utils/date.js"
import { TOAST_DURATION } from "../../../../../ui/static_src/ui/utils/toast.js"
import { createReplacements, getReplacementSuggestions } from "../../services/api.js"

const emit = defineEmits(["replacements-created", "update:modelValue"])

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  carsInCharge: {
    type: Array,
    required: true,
  },
  openReplacementDialog: {
    type: Function,
    required: true,
  },
  insuranceGroups: {
    type: Array,
    required: true,
  },
  monday: {
    type: Date,
    required: true,
  },
})

const toast = useToast()
const replacements = ref([])
const isLoading = ref(false)

const days = computed(() => {
  return new Array(5).fill(0).map((_, i) => {
    const date = new Date(props.monday)
    date.setDate(props.monday.getDate() + i)
    return date
  })
})

const carsPerDay = computed(() => {
  return props.carsInCharge.reduce((acc, car) => {
    const date = new Date(getStateDate(car))
    const day = getIsoString(date)
    if (!acc[day]) acc[day] = []
    acc[day].push({ car, replacement: replacements.value.find(r => parseInt(r.car_incharge.id) === car.id) })
    return acc
  }, {})
})

const checkConflict = () => {
  replacements.value = replacements.value.map(r => {
    if (replacements.value.filter(r2 => r2.replacement_car?.id === r.replacement_car?.id).length > 1) {
      return { ...r, conflict: true }
    } else return { ...r, conflict: false }
  })
  emit("update:modelValue", replacements.value)
}

const getCarMatchingInsuranceGroups = (car) => {
  return props.insuranceGroups.filter(ig => {
    return car.insurances.some(i => ig.insurances.map(ig => ig.id).includes(i.id))
  })
}

const getReplacementsSuggestions = async() => {
  const carsWithReplacement = replacements.value.filter(r => r.replacement_car?.id).map(r => r.car_incharge.id)
  const { data } = await getReplacementSuggestions(
    props.carsInCharge.filter(car => !carsWithReplacement.includes(car.id)).map(c => c.id),
    replacements.value.map(r => r?.replacement_car?.id).filter(id => id),
    props.carsInCharge[0].company_id,
    getIsoString(props.monday),
    getIsoString(getNextFriday(props.monday)),
  )
  replacements.value = [
    ...replacements.value.filter(r => carsWithReplacement.includes(r.car_incharge.id)),
    ...data.map(r => ({ ...r, start_date: new Date(r.start_date), end_date: new Date(r.end_date), isSuggestion: true })),
  ]
  emit("update:modelValue", replacements.value)
  checkConflict()
}

const deleteReplacement = (replacement) => {
  replacements.value = replacements.value.filter(r => r.car_incharge.id !== replacement.car_incharge.id)
  checkConflict()
}

const updateReplacement = (event) => {
  const { detail: { replacement } } = event
  let isUpdated = false
  replacements.value = replacements.value.map(r => {
    if (r.car_incharge.id === replacement.car_incharge.id) {
      isUpdated = true
      return replacement
    }
    return r
  })
  if (!isUpdated) replacements.value.push(replacement)
  emit("update:modelValue", replacements.value)
  checkConflict()
}

const bulkCreateReplacements = async() => {
  if (replacements.value.filter(r => r.conflict).length > 0) return
  const validReplacements = replacements.value.filter(r => r.replacement_car?.id && r.car_incharge?.id)
  isLoading.value = true
  await createReplacements(validReplacements.map(r => ({
    start_date: getIsoString(r.start_date),
    end_date: getIsoString(r.end_date),
    replacement_car_id: r.replacement_car.id,
    car_incharge_id: r.car_incharge.id,
    company_id: r.car_incharge.company_id,
  })))
  toast.add({ severity: "success", summary: "Emprunts crées avec succès", detail: `${validReplacements.length} VR empruntés`, life: TOAST_DURATION })
  emit("replacements-created")
  isLoading.value = false
}

const reset = () => {
  replacements.value = []
  emit("update:modelValue", replacements.value)
}

onMounted(() => {
  document.addEventListener("update-suggested-replacement", updateReplacement)
})
</script>

<template>
  <div class="week-replacement-suggestions">
    <div
      v-for="day in days"
      :key="day"
      class="week-day"
    >
      <div class="week-day-name">
        {{ getDateDayName(day) }}
      </div>
      <div
        v-if="carsPerDay[getIsoString(day)]?.length > 0"
        class="week-day-cars"
      >
        <button
          v-for="{car, replacement} in carsPerDay[getIsoString(day)]"
          :key="car.id"
          :class="{
            'week-day-car': true,
            '-replacement': replacement !== undefined && replacement.replacement_car,
            '-no-hover': replacement && replacement.replacement_car,
          }"
          @click="replacement && replacement.replacement_car ? null : openReplacementDialog(car)"
        >
          <div class="car-details">
            <CarName :car-in-charge="car" />
            <InsuranceGroups
              :insurance-groups="getCarMatchingInsuranceGroups(car)"
              :highlighted-insurances="car.insurances.map(i => i.id)"
            />
          </div>
          <SuggestedReplacement
            v-if="replacement && replacement.replacement_car"
            :replacement="replacement"
            @delete-replacement="deleteReplacement"
            @update-replacement="openReplacementDialog"
          />
        </button>
      </div>
      <div
        v-else
        class="week-day-empty"
      >
        Aucun client en attente d'un VR
      </div>
    </div>
  </div>
  <div class="week-replacements-suggestions-buttons">
    <div
      v-if="isLoading"
      class="loading-data"
    >
      <i class="pi pi-spin pi-cog loading-icon" />
    </div>
    <TransitionGroup
      v-else
      name="list"
      class="week-replacements-suggestions-buttons"
      tag="div"
    >
      <button
        v-if="replacements.length !== carsInCharge.length"
        key="get-replacements-suggestions"
        class="submit"
        @click="getReplacementsSuggestions"
      >
        Obtenir des suggestions
      </button>
      <button
        v-if="replacements.length > 0"
        key="reset-replacements"
        class="submit"
        @click="reset"
      >
        Réinitialiser
      </button>
      <button
        v-if="replacements.length > 0"
        key="create-replacements"
        class="submit"
        @click="bulkCreateReplacements"
      >
        Créer les remplacements
      </button>
    </TransitionGroup>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.week-replacement-suggestions {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  width: 75rem;
  padding: 1rem 0;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}

.week {
  width: 100%;
  height: 100%;
}

.week-day-name {
  @include subtitle;
  text-align: center;
  width: 100%;
}

.week-day-cars {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .4rem;
  width: 100%;
}

.week-day-car {
  @include body;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--color-border-primary);
  padding-inline: 6px;
  height: 1.6rem;
  gap: .2rem;
  border-radius: var(--radius);
  overflow: hidden;
  transition: height 0.3s ease-in-out;

  &.-replacement {
    height: 9.6rem;
  }

  &.-no-hover:hover {
    cursor: auto;
    background-color: transparent;
  }
}

.car-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.week-day-empty {
  @include body;
  text-align: center;
}

.week-replacements-suggestions-buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 1rem;
}

.loading-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-icon {
  color: var(--color-text-primary);
  font-size: 2rem;
}

.list-enter-active,
.list-leave-active {
  transition: all var(--p-transition-duration) ease;
}

.list-move {
  transition: transform var(--p-transition-duration) ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.list-enter-to,
.list-leave-from {
  opacity: 1;
  transform: scale(1);
}

.list-leave-active {
  position: absolute;
  top: 0;
}
</style>
