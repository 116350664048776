import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createBlock($setup["SpeedDial"], {
    direction: "left",
    model: $setup.items,
    class: "replacement-speed-dial",
    onHide: _cache[0] || (_cache[0] = $event => ($setup.isButtonToggled = false)),
    onShow: _cache[1] || (_cache[1] = $event => ($setup.isButtonToggled = true))
  }, {
    button: _withCtx(({ toggleCallback }) => [
      _withDirectives((_openBlock(), _createElementBlock("button", {
        class: "replacement-car-button",
        onClick: toggleCallback
      }, [
        (_openBlock(), _createElementBlock("svg", {
          class: _normalizeClass(['replacement-car-icon', {'-finished': $props.isReplacementFinished, '-affected': $props.isReplacementAffected && !$props.isReplacementFinished }])
        }, [
          _createElementVNode("use", {
            href: $setup.svgLink('cars', 'car-replacement')
          }, null, 8 /* PROPS */, _hoisted_2)
        ], 2 /* CLASS */))
      ], 8 /* PROPS */, _hoisted_1)), [
        [
          _directive_tooltip,
          $setup.isButtonToggled ? null : $setup.replacementTooltipOptions,
          void 0,
          { left: true }
        ]
      ])
    ]),
    item: _withCtx(({ item }) => [
      (item.label === 'edit')
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "replacement-speed-dial-item-button",
            onClick: $setup.openReplacementDialog
          }, [
            _createVNode($setup["Pencil"], { size: "16" })
          ])), [
            [
              _directive_tooltip,
              { value: 'Modifier le remplacement', showDelay: 500 },
              void 0,
              { bottom: true }
            ]
          ])
        : _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "replacement-speed-dial-item-button -danger",
            onClick: $setup.openReplacementDeletionModal
          }, [
            _createVNode($setup["Trash2"], { size: "16" })
          ])), [
            [
              _directive_tooltip,
              { value: 'Supprimer le remplacement', showDelay: 500 },
              void 0,
              { bottom: true }
            ]
          ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model"]))
}