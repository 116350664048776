<script setup>
import { Form } from "@primevue/forms"
import { storeToRefs } from "pinia"
import { useToast } from "primevue"
import Dialog from "primevue/dialog"
import FloatLabel from "primevue/floatlabel"
import InputText from "primevue/inputtext"
import Select from "primevue/select"
import { ref } from "vue"

import { TOAST_DURATION } from "../../../../../../ui/static_src/ui/utils/toast.js"
import { getStaff, patchStaff, postStaff } from "../../../services/api.js"
import useStaffStore from "../../../stores/staff.js"

const staff = useStaffStore()
const toast = useToast()
const loading = ref(false)
const { focusedStaff, isStaffModalOpen, staffObject } = storeToRefs(staff)

const updateModel = (key, value) => {
  focusedStaff.value[key] = value
}

const staffDomainValues = {
  Tôlier: "sheet-metal-worker",
  Peintre: "painter",
  Mécanicien: "mechanic",
}

const staffDomainNames = {
  "sheet-metal-worker": "Tôlier",
  painter: "Peintre",
  mechanic: "Mécanicien",
}

const staffRankValues = {
  Senior: "senior",
  Apprenti: "apprentice",
}

const staffRankNames = {
  senior: "Senior",
  apprentice: "Apprenti",
}

const resolver = () => {
  const errors = { first_name: [], last_name: [], domain: [], rank: [] }
  if (!focusedStaff.value.first_name) errors.first_name.push({ type: "required" })
  if (!focusedStaff.value.last_name) errors.last_name.push({ type: "required" })
  if (!focusedStaff.value.domain) errors.domain.push({ type: "required" })
  if (!focusedStaff.value.rank) errors.rank.push({ type: "required" })
  return { errors }
}

const onFormSubmit = async({ valid }) => {
  if (!valid) return
  loading.value = true
  if (!focusedStaff.value.id) {
    await postStaff(focusedStaff.value)
    toast.add({
      severity: "success",
      summary: "Compagnon ajouté",
      detail: `Le compagnon ${focusedStaff.value.first_name} ${focusedStaff.value.last_name} a bien été ajouté`,
      life: TOAST_DURATION,
    })
  } else {
    await patchStaff(focusedStaff.value)
    toast.add({
      severity: "success",
      summary: "Compagnon modifié",
      detail: `Les informations du compagnon ${focusedStaff.value.first_name} ${focusedStaff.value.last_name} ont bien été mises à jour`,
      life: TOAST_DURATION,
    })
  }
  if (staffObject.value?.id) {
    const { data } = await getStaff(staffObject.value.id)
    staffObject.value = data
  } else await staff.fetchStaff()
  loading.value = false
  staff.isStaffModalOpen = false
}
</script>

<template>
  <Dialog
    v-model:visible="isStaffModalOpen"
    :header="focusedStaff?.id ? 'Modifier le compagnon' : 'Ajouter un compagnon'"
    modal
  >
    <Form
      :resolver="resolver"
      @submit="onFormSubmit"
    >
      <div class="staff-form-block">
        <FloatLabel variant="on">
          <InputText
            id="first_name"
            name="first_name"
            :model-value="focusedStaff.first_name"
            style="min-width: 12.5rem;"
            @update:model-value="(value) => updateModel('first_name', value)"
          />
          <label for="first_name">Prénom</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <InputText
            id="last_name"
            name="last_name"
            :model-value="focusedStaff.last_name"
            style="min-width: 12.5rem;"
            @update:model-value="(value) => updateModel('last_name', value)"
          />
          <label for="last_name">Nom</label>
        </FloatLabel>
      </div>
      <div class="staff-form-block -margin-top">
        <FloatLabel variant="on">
          <Select
            id="domain"
            name="domain"
            :model-value="staffDomainNames[focusedStaff.domain]"
            :options="['Tôlier', 'Peintre', 'Mécanicien']"
            style="width: 12.5rem;"
            @value-change="(value) => updateModel('domain', staffDomainValues[value])"
          />
          <label for="state">Domaine</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <Select
            id="rank"
            name="rank"
            :model-value="staffRankNames[focusedStaff.rank]"
            :options="['Senior', 'Apprenti']"
            style="width: 12.5rem;"
            @value-change="(value) => updateModel('rank', staffRankValues[value])"
          />
          <label for="state">Expérience</label>
        </FloatLabel>
      </div>
      <div class="staff-form-validate">
        <i
          v-if="loading"
          class="pi pi-spin pi-cog loading-icon"
        />
        <button
          v-else
          class="staff-form-button"
          type="submit"
        >
          {{ focusedStaff?.id ? 'Modifier' : 'Ajouter' }}
        </button>
      </div>
    </Form>
  </Dialog>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.staff-form-block {
  display: flex;
  gap: 1rem;
  margin-top: .4rem;

  &.-margin-top {
    margin-top: 1rem;
  }
}

.staff-form-validate {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.staff-form-button {
  @include body;
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: .4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--color-button-bg-submit-full-hover);
  }
}
</style>
