import { createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "staff-timeline-contract"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "staff-timeline-date" }
const _hoisted_5 = {
  key: 0,
  class: "staff-timeline-marker -start"
}
const _hoisted_6 = {
  key: 1,
  class: "staff-timeline-marker -end"
}
const _hoisted_7 = {
  key: 2,
  class: "staff-timeline-marker-date"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return ($setup.staffObject.absence_set.length > 0)
    ? (_openBlock(), _createBlock($setup["Timeline"], {
        key: 0,
        value: $setup.absenceTimeline,
        class: _normalizeClass(['staff-timeline-content', { '-dialog': $props.isDialog }])
      }, {
        content: _withCtx((slotProps) => [
          (slotProps.item.dateType === 'end' && !$props.isDialog)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _withDirectives((_openBlock(), _createElementBlock("button", {
                  class: "staff-timeline-edit",
                  onClick: $event => ($setup.staff.openAbsenceModal(
            $setup.staffObject,
            $setup.staffObject.absence_set.find(a => a.id === slotProps.item.absenceId)
          ))
                }, [
                  _createVNode($setup["Pencil"], { size: "14" })
                ], 8 /* PROPS */, _hoisted_2)), [
                  [
                    _directive_tooltip,
                    { value: 'Modifier l\'absence', showDelay: 500 },
                    void 0,
                    { top: true }
                  ]
                ]),
                _withDirectives((_openBlock(), _createElementBlock("button", {
                  class: "staff-timeline-delete",
                  onClick: $event => ($setup.openAbsenceDeletionModal(slotProps.item.absenceId))
                }, [
                  _createVNode($setup["X"], { size: "14" })
                ], 8 /* PROPS */, _hoisted_3)), [
                  [
                    _directive_tooltip,
                    { value: 'Supprimer l\'absence', showDelay: 500 },
                    void 0,
                    { top: true }
                  ]
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        opposite: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_4, _toDisplayString($setup.getFullDate(slotProps.item.date) + ' - ' + slotProps.item.date_at), 1 /* TEXT */)
        ]),
        marker: _withCtx((slotProps) => [
          (slotProps.item.dateType === 'start' && $setup.isBeforeToday(slotProps.item.date))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode($setup["Check"], {
                  size: "14",
                  class: "staff-timeline-icon"
                })
              ]))
            : (slotProps.item.dateType === 'end' && slotProps.item.date && $setup.isBeforeToday(slotProps.item.date))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode($setup["Check"], {
                    size: "14",
                    class: "staff-timeline-icon"
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["value", "class"]))
    : _createCommentVNode("v-if", true)
}