export const state = (replacement) => {
  const now = new Date().getTime()
  if (replacement.finished && new Date(replacement.end_date).getTime() < now) {
    return "finished"
  }
  if (new Date(replacement.start_date).getTime() > now) {
    return "coming"
  }
  return "in-progress"
}

const getScore = (insurances, insuranceGroups) => {
  if (!insurances) return 0
  for (const insuranceGroup of insuranceGroups) {
    for (const insurance of insuranceGroup.insurances) {
      if (insurances.includes(insurance.id)) return 1
    }
  }
  return 0
}

export const aggregateSuggestedReplacements = (replacementCars, replacements) => {
  return replacementCars.map(replacementCar => {
    const replacement = replacements.find(r => r.replacement_car.id === replacementCar.id)
    if (!replacement) return replacementCar
    return {
      brand: replacement.replacement_car.brand,
      model: replacement.replacement_car.model,
      registration: replacement.replacement_car.registration,
      id: replacement.replacement_car.id,
      first_registration_date: replacement.replacement_car.first_registration_date,
      insurance_groups: replacement.replacement_car.insurance_groups,
      replacement_car_incharge: replacement?.car_incharge,
      score: getScore(replacement?.car_incharge?.insurances.map(i => i.id) || [], replacement.replacement_car.insurance_groups),
      unavailbale_reason: "Emprunté",
      unavailable_since: replacement.start_date,
      unavailable_up: replacement.end_date,
    }
  })
}

export const getWeekSlotStyle = (startDate, endDate, monday) => {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const _date = new Date(monday)
  _date.setHours(1, 0, 0, 0)
  let length = 0
  let marginLeft = 0
  let marginRight = 0
  while (_date.getDay() !== 6) {
    if (_date.getFullYear() === start.getFullYear() && _date.getMonth() === start.getMonth() && _date.getDate() === start.getDate()) {
      length += (24 - start.getHours() - (start.getMinutes() / 60)) / 24
      marginLeft += (start.getHours() + (start.getMinutes() / 60)) / 24
    } else if (_date.getFullYear() === end.getFullYear() && _date.getMonth() === end.getMonth() && _date.getDate() === end.getDate()) {
      length += (end.getHours() + (end.getMinutes() / 60)) / 24
      marginRight += (24 - end.getHours() - (end.getMinutes() / 60)) / 24
    } else if (start.getTime() < _date.getTime() && end.getTime() > _date.getTime()) {
      length += 1
    } else {
      marginLeft += 1
    }
    _date.setDate(_date.getDate() + 1)
  }

  return { width: `${length / 5 * 100}%`, marginLeft: `${marginLeft / 5 * 100}%`, marginRight: `${marginRight / 5 * 100}%`, length }
}
