<script setup>
import { ArrowBigRight, X } from "lucide-vue-next"

import InsuranceGroups from "../_partials/InsuranceGroups.vue"

import { getFullDate } from "../../../../../ui/static_src/ui/utils/date.js"

const emit = defineEmits(["delete-replacement", "update-replacement"])

const props = defineProps({
  replacement: {
    type: Object,
    required: true,
  },
})

const updateReplacement = (event) => {
  if (event.target.closest(".delete-replacement-button")) return
  emit("update-replacement", props.replacement.car_incharge, props.replacement)
}

const deleteReplacement = (replacement, event) => {
  emit("delete-replacement", replacement)
  event.preventDefault()
  event.stopPropagation()
}
</script>

<template>
  <button
    :class="['replacement', { '-conflict': replacement.conflict }]"
    @click="updateReplacement"
  >
    <div class="delete-replacement-container">
      <button
        v-tooltip.right="{ value: 'Supprimer le remplacement', showDelay: 500 }"
        class="delete-replacement-button"
        @click="(event) => deleteReplacement(replacement, event)"
      >
        <X size="18" />
      </button>
    </div>
    <div class="replacement-dates">
      <div class="replacement-date">
        {{ getFullDate(replacement.start_date) }}
      </div>
      <ArrowBigRight class="replacement-date" />
      <div class="replacement-date">
        {{ getFullDate(replacement.end_date) }}
      </div>
    </div>
    <div class="replacement-details">
      <div class="replacement-car">
        <span class="replacement-car-text">{{ replacement.replacement_car.registration }} - {{ replacement.replacement_car.brand }} - {{ replacement.replacement_car.model }}</span>
      </div>
      <InsuranceGroups :insurance-groups="replacement.replacement_car.insurance_groups" />
    </div>
  </button>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

@keyframes highlightBorderNoConflict {
  0% {
    border-color: var(--color-border-primary);
  }
  25% {
    border-color: var(--color-text-submit);
  }
  75% {
    border-color: var(--color-text-submit);
  }
  100% {
    border-color: var(--color-border-primary);
  }
}

@keyframes highlightBorderConflict {
  0% {
    border-color: var(--color-border-primary);
  }
  100% {
    border-color: var(--color-text-warning);
  }
}

.replacement {
  display: flex;
  flex-direction: column;
  gap: .4rem;
  padding: .4rem;
  border: 1px dashed var(--color-border-primary);
  border-radius: var(--radius);
  width: 100%;
  transition: background-color var(--p-transition-duration) ease-in-out;
  &:hover {
    background-color: var(--content-bg--color-focus);
  }

  &:has(.delete-replacement-button:hover) {
    background-color: transparent;
  }

  &.-conflict {
    border-color: var(--color-text-warning);
    animation: highlightBorderConflict 1s ease-in-out;
  }

  &:not(-conflict) {
    animation: highlightBorderNoConflict 1s ease-in-out;
  }
}

.replacement-details {
  display: flex;
  align-items: flex-start;
  gap: .4rem;
  width: 100%;
  padding-top: .2rem;
}

.replacement-car {
  @include body;
  display: flex;
  width: 100%;
  margin-top: -.4rem;
}

.replacement-car-text {
  max-width: 10rem;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  display: block;
  text-align: left;
}

.replacement-dates {
  display: flex;
  align-items: center;
  gap: .4rem;
  width: 100%;
}

.replacement-date {
  @include body;
  color: var(--color-text-submit);
}

.delete-replacement-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.delete-replacement-button {
  color: var(--color-icon);

  &:hover {
    color: var(--color-text-warning);
  }
}
</style>
