import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withMemo as _withMemo, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "calendar-week-days" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.weekData.key === 'sentinel-top' || $props.weekData.key === 'sentinel-bottom')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: "weekRef",
        class: _normalizeClass(['sentinel', {'-bottom': $props.weekData.key === 'sentinel-bottom'}])
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("i", {
          class: "pi pi-spin pi-cog loading-icon",
          style: {"font-size":"2rem"}
        }, null, -1 /* HOISTED */)
      ]), 2 /* CLASS */))
    : ($setup.props.weekData?.data !== null)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref: "weekRef",
          class: _normalizeClass(['calendar-week', { '-visible': $setup.isVisible, '-padding-right': $props.paddingRight }])
        }, [
          _withMemo([$setup.props.weekData.monday, $setup.weekCapacity, $setup.currentCars, $setup.currentDelayedWorks, $setup.calendarStore.getReplacementCarsCount, $setup.dailyWorkCapacity], () => _createVNode($setup["CalendarWeekHeader"], {
            monday: $setup.props.weekData.monday,
            cars: $setup.currentCars,
            "delayed-works": $setup.currentDelayedWorks,
            "replacement-capacity": $setup.calendarStore.getReplacementCarsCount,
            "week-capacity": $setup.weekCapacity,
            "delay-animation": $setup.props.weekData.delayAnimation
          }, null, 8 /* PROPS */, ["monday", "cars", "delayed-works", "replacement-capacity", "week-capacity", "delay-animation"]), _cache, 0),
          _createVNode(_TransitionGroup, {
            class: "calendar-week-notes",
            tag: "div",
            name: "list"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.notes, (note) => {
                return (_openBlock(), _createBlock($setup["CalendarNote"], {
                  key: note.id,
                  note: note,
                  monday: $setup.props.weekData.monday
                }, null, 8 /* PROPS */, ["note", "monday"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("div", _hoisted_1, [
            _withMemo([$setup.days[0], $setup.currentMondayCars, $setup.currentMondayDelayedWorks, $setup.currentMondayCapacity], () => _createVNode($setup["CalendarDay"], {
              day: $setup.days[0],
              cars: $setup.currentMondayCars,
              "delayed-works": $setup.currentMondayDelayedWorks,
              "day-capacity": $setup.currentMondayCapacity,
              "delay-animation": $setup.props.weekData.delayAnimation,
              "public-holiday-name": $setup.getPublicHolidayName($setup.days[0])
            }, null, 8 /* PROPS */, ["day", "cars", "delayed-works", "day-capacity", "delay-animation", "public-holiday-name"]), _cache, 1),
            _withMemo([$setup.days[1], $setup.currentThursdayCars, $setup.currentThursdayDelayedWorks, $setup.currentThursdayCapacity], () => _createVNode($setup["CalendarDay"], {
              day: $setup.days[1],
              cars: $setup.currentThursdayCars,
              "delayed-works": $setup.currentThursdayDelayedWorks,
              "day-capacity": $setup.currentThursdayCapacity,
              "delay-animation": $setup.props.weekData.delayAnimation,
              "public-holiday-name": $setup.getPublicHolidayName($setup.days[1])
            }, null, 8 /* PROPS */, ["day", "cars", "delayed-works", "day-capacity", "delay-animation", "public-holiday-name"]), _cache, 2),
            _withMemo([$setup.days[2], $setup.currentWednesdayCars, $setup.currentWednesdayDelayedWorks, $setup.currentWednesdayCapacity], () => _createVNode($setup["CalendarDay"], {
              day: $setup.days[2],
              cars: $setup.currentWednesdayCars,
              "delayed-works": $setup.currentWednesdayDelayedWorks,
              "day-capacity": $setup.currentWednesdayCapacity,
              "delay-animation": $setup.props.weekData.delayAnimation,
              "public-holiday-name": $setup.getPublicHolidayName($setup.days[2])
            }, null, 8 /* PROPS */, ["day", "cars", "delayed-works", "day-capacity", "delay-animation", "public-holiday-name"]), _cache, 3),
            _withMemo([$setup.days[3], $setup.currentTuesdayCars, $setup.currentTuesdayDelayedWorks, $setup.currentTuesdayCapacity], () => _createVNode($setup["CalendarDay"], {
              day: $setup.days[3],
              cars: $setup.currentTuesdayCars,
              "delayed-works": $setup.currentTuesdayDelayedWorks,
              "day-capacity": $setup.currentTuesdayCapacity,
              "delay-animation": $setup.props.weekData.delayAnimation,
              "public-holiday-name": $setup.getPublicHolidayName($setup.days[3])
            }, null, 8 /* PROPS */, ["day", "cars", "delayed-works", "day-capacity", "delay-animation", "public-holiday-name"]), _cache, 4),
            _withMemo([$setup.days[4], $setup.currentFridayCars, $setup.currentFridayDelayedWorks, $setup.currentFridayCapacity], () => _createVNode($setup["CalendarDay"], {
              day: $setup.days[4],
              cars: $setup.currentFridayCars,
              "delayed-works": $setup.currentFridayDelayedWorks,
              "day-capacity": $setup.currentFridayCapacity,
              "delay-animation": $setup.props.weekData.delayAnimation,
              "public-holiday-name": $setup.getPublicHolidayName($setup.days[4])
            }, null, 8 /* PROPS */, ["day", "cars", "delayed-works", "day-capacity", "delay-animation", "public-holiday-name"]), _cache, 5)
          ])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
}