<script setup>
import { orderBy } from "lodash-es"
import { Check, Pencil, X } from "lucide-vue-next"
import { storeToRefs } from "pinia"
import { Timeline } from "primevue"
import { useConfirm } from "primevue/useconfirm"
import { useToast } from "primevue/usetoast"
import { computed } from "vue"

import { getFullDate } from "../../../../../../ui/static_src/ui/utils/date.js"
import { TOAST_DURATION } from "../../../../../../ui/static_src/ui/utils/toast.js"
import useStaffStore from "../../../stores/staff.js"

defineProps({
  isDialog: {
    type: Boolean,
    default: false,
  },
})

const toast = useToast()
const confirm = useConfirm()
const staff = useStaffStore()
const { staffObject } = storeToRefs(staff)

const isBeforeToday = (strDate) => {
  return new Date(strDate).getTime() < new Date().getTime()
}

const halfDayNames = {
  morning: "Matin",
  afternoon: "Après-midi",
}

const absenceTimeline = computed(() => {
  const timeline = []
  staffObject.value.absence_set.forEach((absence) => {
    timeline.push({
      absenceId: absence.id,
      date: new Date(absence.start_date),
      date_at: halfDayNames[absence.start_at],
      dateType: "start",
    })
    timeline.push({
      absenceId: absence.id,
      date: new Date(absence.end_date),
      date_at: halfDayNames[absence.end_at],
      dateType: "end",
    })
  })
  return orderBy(timeline, "date", "desc")
})

const openAbsenceDeletionModal = (absenceId) => {
  confirm.require({
    message: "Êtes-vous sûr de vouloir supprimer l'absence ?",
    header: "Confirmation",
    icon: "pi pi-exclamation-triangle",
    rejectProps: { label: "Annuler" },
    acceptProps: { label: "Confirmer", severity: "danger" },
    accept: () => {
      staff.deleteAbsence(staffObject.value.id, absenceId)
      toast.add({
        severity: "success",
        summary: "Absence supprimée",
        detail: "L'absence a bien été supprimée",
        life: TOAST_DURATION,
      })
    },
    reject: () => {},
  })
}
</script>

<template>
  <Timeline
    v-if="staffObject.absence_set.length > 0"
    :value="absenceTimeline"
    :class="['staff-timeline-content', { '-dialog': isDialog }]"
  >
    <template #content="slotProps">
      <div
        v-if="slotProps.item.dateType === 'end' && !isDialog"
        class="staff-timeline-contract"
      >
        <button
          v-tooltip.top="{ value: 'Modifier l\'absence', showDelay: 500 }"
          class="staff-timeline-edit"
          @click="staff.openAbsenceModal(
            staffObject,
            staffObject.absence_set.find(a => a.id === slotProps.item.absenceId)
          )"
        >
          <Pencil size="14" />
        </button>
        <button
          v-tooltip.top="{ value: 'Supprimer l\'absence', showDelay: 500 }"
          class="staff-timeline-delete"
          @click="openAbsenceDeletionModal(slotProps.item.absenceId)"
        >
          <X size="14" />
        </button>
      </div>
    </template>
    <template #opposite="slotProps">
      <div class="staff-timeline-date">
        {{ getFullDate(slotProps.item.date) + ' - ' + slotProps.item.date_at }}
      </div>
    </template>
    <template #marker="slotProps">
      <div
        v-if="slotProps.item.dateType === 'start' && isBeforeToday(slotProps.item.date)"
        class="staff-timeline-marker -start"
      >
        <Check
          size="14"
          class="staff-timeline-icon"
        />
      </div>
      <div
        v-else-if="slotProps.item.dateType === 'end' && slotProps.item.date && isBeforeToday(slotProps.item.date)"
        class="staff-timeline-marker -end"
      >
        <Check
          size="14"
          class="staff-timeline-icon"
        />
      </div>
      <div
        v-else
        class="staff-timeline-marker-date"
      />
    </template>
  </Timeline>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.staff-timeline-marker-date {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: baseline;
  border-width: 2px;
  border-style: solid;
  border-color: var(--color-text-secondary);
  border-radius: 999px;
  width: 18px;
  height: 18px;
}

.staff-timeline-marker {
  background-color: var(--color-button-bg-submit);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;

  &.-end {
    background-color: var(--color-text-success);
  }
}

.staff-timeline-icon {
  color: var(--color-text-quaternary);
}

.staff-timeline-contract,
.staff-timeline-date {
  @include body;
  display: flex;
  align-items: center;
  gap: .4rem;
  transform: translateY(-0.2rem);
}

.staff-timeline-edit {
  padding: .4rem;
  color: var(--color-text-secondary);
  transform: translateY(-0.2rem);

  &:hover {
    color: var(--color-text-primary);
  }
}

.staff-timeline-delete {
  padding: .4rem;
  color: var(--color-text-secondary);
  transform: translateY(-0.2rem);

  &:hover {
    color: var(--color-text-warning);
  }
}
</style>
