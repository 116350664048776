<script setup>
import { asyncComputed } from "@vueuse/core/index.cjs"
import { Pencil, Trash2 } from "lucide-vue-next"
import SpeedDial from "primevue/speeddial"
import { useConfirm } from "primevue/useconfirm"
import { useToast } from "primevue/usetoast"
import { computed, h, ref } from "vue"
import { renderToString } from "vue/server-renderer"

import ReplacementCar from "../../../../../../cars/static_src/cars/components/tooltips/ReplacementCar.vue"

import { svgLink } from "../../../../../../ui/static_src/ui/utils/svg.js"
import { TOAST_DURATION } from "../../../../../../ui/static_src/ui/utils/toast.js"
import { useCalendarStore } from "../../../stores/calendar.js"
import { useCarStore } from "../../../stores/car.js"

const props = defineProps({
  car: {
    type: Object,
    required: true,
  },
  replacement: {
    type: Object,
    required: true,
  },
  isReplacementFinished: {
    type: Boolean,
    default: false,
  },
  isReplacementAffected: {
    type: Boolean,
    default: false,
  },
})

const isButtonToggled = ref(false)
const confirm = useConfirm()
const toast = useToast()
const calendar = useCalendarStore()
const carStore = useCarStore()

const items = computed(() => ([
  { label: "edit" },
  { label: "delete" },
]))

const openReplacementDeletionModal = () => {
  confirm.require({
    message: `Êtes-vous sûr de vouloir supprimer le remplacement pour le client ${props.replacement.car_incharge.owner_last_name} ?`,
    header: "Confirmation",
    icon: "pi pi-exclamation-triangle",
    rejectProps: { label: "Annuler" },
    acceptProps: { label: "Confirmer", severity: "danger" },
    accept: () => {
      calendar.deleteReplacement(props.replacement)
      toast.add({
        severity: "success",
        summary: "Remplacement supprimé",
        detail: `L'emprunt pour le client ${props.replacement.car_incharge.owner_last_name} a bien été supprimé`,
        life: TOAST_DURATION,
      })
    },
    reject: () => {},
  })
}

const openReplacementDialog = () => {
  calendar.replacementModalOpen = true
  carStore.focusedCar = props.car
}

const replacementTooltipOptions = asyncComputed(async() => {
  const htmlContent = await renderToString(h(ReplacementCar, {
    replacement: props.replacement,
  }))
  return { escape: false, value: htmlContent }
})
</script>

<template>
  <SpeedDial
    direction="left"
    :model="items"
    class="replacement-speed-dial"
    @hide="isButtonToggled = false"
    @show="isButtonToggled = true"
  >
    <template #button="{ toggleCallback }">
      <button
        v-tooltip.left="isButtonToggled ? null : replacementTooltipOptions"
        class="replacement-car-button"
        @click="toggleCallback"
      >
        <svg
          :class="['replacement-car-icon', {'-finished': isReplacementFinished, '-affected': isReplacementAffected && !isReplacementFinished }]"
        >
          <use :href="svgLink('cars', 'car-replacement')" />
        </svg>
      </button>
    </template>
    <template #item="{ item }">
      <button
        v-if="item.label === 'edit'"
        v-tooltip.bottom="{ value: 'Modifier le remplacement', showDelay: 500 }"
        class="replacement-speed-dial-item-button"
        @click="openReplacementDialog"
      >
        <Pencil size="16" />
      </button>
      <button
        v-else
        v-tooltip.bottom="{ value: 'Supprimer le remplacement', showDelay: 500 }"
        class="replacement-speed-dial-item-button -danger"
        @click="openReplacementDeletionModal"
      >
        <Trash2 size="16" />
      </button>
    </template>
  </SpeedDial>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.replacement-car-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 0;
  padding-block: 0;

  &:hover {
    background-color: transparent;
    transform: scale(1.1);
  }
}

.replacement-speed-dial-item-button {
  @include body;
  padding: .2rem;
  border-radius: 50%;
  color: var(--color-text-secondary);
  background-color: var(--content-bg--floating-button);

  &:hover {
    color: var(--color-text-primary);
  }

  &.-danger:hover {
    color: var(--color-text-warning);
  }
}

.replacement-car-icon {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-text-submit);

  &.-finished {
    color: var(--color-text-success);
  }

  &.-affected {
    color: var(--color-text-feedback);
  }
}
</style>
