// --- Server API ---

export const getCars = async(queryParams, companyPk, page, body) => {
  const { query, states } = queryParams
  const statesString = states.join("&state=")
  return await api("GET", `/api/cars-in-charge/?query=${query}&company_pk=${companyPk}&state=${statesString}&page=${page}&search=true`, body)
}

export const getCarInCharge = async(carPk) => {
  return await api("GET", `/api/cars-in-charge/${carPk}/?company_pk=${window.CARS_ENV.company_pk}`)
}

export const getReplacementCar = async(carPk) => {
  return await api("GET", `/api/cars-replacements/${carPk}/?company_pk=${window.CARS_ENV.company_pk}`)
}

export const putReplacementCar = async(body) => {
  return await api("PUT", `/api/cars-replacements/${body.id}/?company_pk=${window.CARS_ENV.company_pk}`, body)
}

export const postMaintenanceTime = async(body) => {
  return await api("POST", `/api/maintenance-times/?company_pk=${window.CARS_ENV.company_pk}`, body)
}

export const putMaintenanceTime = async(body) => {
  return await api("PUT", `/api/maintenance-times/${body.id}/?company_pk=${window.CARS_ENV.company_pk}`, body)
}

export const deleteMaintenanceTime = async(maintenanceTimeId) => {
  return await api("DELETE", `/api/maintenance-times/${maintenanceTimeId}/?company_pk=${window.CARS_ENV.company_pk}`)
}

export const deleteReplacement = async(replacementId) => {
  return await api("DELETE", `/api/replacements/${replacementId}/?company_pk=${window.CARS_ENV.company_pk}`)
}

export const updateCarInCharge = async(body) => {
  return await api("PATCH", `/api/cars-in-charge/${body.id}/?company_pk=${window.CARS_ENV.company_pk}`, body)
}

export const getInsurances = async(name, excludeList) => {
  let url = `/api/insurances/?query=${name}`
  if (excludeList) {
    excludeList.forEach(pk => {
      url += `&exclude=${pk}`
    })
  }
  return await api("GET", url)
}

export const updateCarState = async(body) => {
  return await api("POST", `/api/cars-in-charge/${body.id}/change-state/?company_pk=${window.CARS_ENV.company_pk}`, body)
}

export const getReplacementCars = async(companyPk) => {
  return await api("GET", `/api/cars-replacements/?company_pk=${companyPk}`)
}

export const postReplacementCar = async(body) => {
  return await api("POST", `/api/cars-replacements/?company_pk=${window.CARS_ENV.company_pk}`, body)
}

export const getMaintenanceTimeOfWeek = async(monday, companyPk) => {
  return await api("GET", `/api/maintenance-times/?monday=${monday}&company_pk=${companyPk}`)
}

export const getReplacementsOfWeek = async(monday, companyPk) => {
  return await api("GET", `/api/replacements/?monday=${monday}&company_pk=${companyPk}`)
}

export const checkReplacementConflict = async(body) => {
  return await api("GET", `/api/replacements/check-conflict/?company_pk=${window.CARS_ENV?.company_pk || window.CALENDAR_ENV?.company_pk}&start_date=${body.startDate}&end_date=${body.endDate}&replacement_car_id=${body.replacementCarId}`)
}

export const updateReplacement = async(body) => {
  return await api("PATCH", `/api/replacements/${body.id}/?company_pk=${window.CARS_ENV.company_pk}`, body)
}

export const getReplacementCarsSuggestions = async(body) => {
  let url = `/api/cars-replacements/suggestions/?company_pk=${window.CALENDAR_ENV?.company_pk || window.CARS_ENV.company_pk}&monday=${body.monday}&query=${body.query}`
  body.insurances.forEach(i => {
    url += `&insurance=${i}`
  })
  return await api("GET", url)
}

export const getReplacementSuggestions = async(carInChargeIds, ignoredReplacementCarIds, companyPk, startDate, endDate) => {
  let url = `/api/replacements/suggest/?company_pk=${companyPk}&start_date=${startDate}&end_date=${endDate}`
  carInChargeIds.forEach(id => {
    url += `&car_in_charge_id=${id}`
  })
  ignoredReplacementCarIds.forEach(id => {
    url += `&replacement_car_id=${id}`
  })
  return await api("GET", url)
}

export const createReplacements = async(body) => {
  return await api("POST", `/api/replacements/bulk-create/?company_pk=${window.CALENDAR_ENV.company_pk}`, body)
}

// --- Internals ---

const api = async(method, path, body) => {
  body = body || {}
  let data
  // eslint-disable-next-line compat/compat
  const response = await fetch(path, {
    method: method || "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": window.CSRF_TOKEN,
    },
    body: method === "GET" ? undefined : JSON.stringify(body),
  })
  if (response.ok && !path.includes("format=")) {
    try {
      data = await response.json()
    } catch (e) {
      // No-op
    }
  }
  return { response, data }
}
