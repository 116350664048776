<script setup>
import { ArrowRight, Calendar, CalendarClock, Folder, Pencil, X } from "lucide-vue-next"
import { useToast } from "primevue/usetoast"

import { TOAST_DURATION } from "../../../../../ui/static_src/ui/utils/toast.js"
import { useCalendarStore } from "../../stores/calendar.js"
import { useCarStore } from "../../stores/car.js"
import { carObject } from "../../utils/cars.js"

const props = defineProps({
  object: {
    type: Object,
    required: true,
  },
})

const calendarStore = useCalendarStore()
const carStore = useCarStore()
const toast = useToast()

const car = carObject(props.object)
const isDelayedWork = props.object.type === "work"

const rescheduleCar = () => {
  if (calendarStore.isHomepage) return
  calendarStore.rescheduleCarInCharge(car).then(() => {
    toast.add({
      severity: "success",
      summary: `${car.owner_last_name} | ${car.model}`,
      detail: "Le véhicule a été reprogrammé",
      life: TOAST_DURATION,
    })
  })
}

const openCarFolder = () => {
  if (calendarStore.isHomepage) return
  if (props.object.type === "car") carStore.openCarInChargeForm(props.object.car)
  else carStore.openCarInChargeForm(props.object.work.car)
}

const changeCalendarDate = (event) => {
  if (calendarStore.isHomepage) return
  if (props.object.type === "car") {
    const carFromList = calendarStore.cars.find((c) => c.id === car.id)
    carStore.setDraggingCar(carFromList, event)
    calendarStore.removeDraggingCarStateDate(carFromList.id)
  } else {
    const workFromList = calendarStore.delayedWorks.find((w) => w.id === props.object.work.id)
    carStore.setDraggingWork(workFromList, event)
    calendarStore.removeDraggingWorkDate(workFromList.id)
  }
}

const updateDelayedWork = () => {
  if (calendarStore.isHomepage) return
  carStore.focusedWork = props.object.work
  carStore.updatingDelayedWork = true
  carStore.delayedWorkModalOpen = true
}

const delayWork = () => {
  if (calendarStore.isHomepage) return
  if (props.object.type === "car") carStore.focusedCar = car
  else carStore.focusedWork = props.object.work

  carStore.delayedWorkModalOpen = true
  carStore.creatingDelayedWork = true
}
</script>

<template>
  <div class="car-options">
    <div class="car-options-delimiter" />
    <button
      v-if="isDelayedWork && !car.is_deleted"
      class="car-option-button"
      @click="updateDelayedWork(props.object)"
    >
      <CalendarClock
        size="18"
        class="car-option-icon"
      />
      <div class="car-option-text">
        Heures Reportées
      </div>
    </button>
    <button
      v-if="!car.is_deleted"
      class="car-option-button"
      @click="openCarFolder(props.object)"
    >
      <Pencil
        size="18"
        class="car-option-icon"
      />
      <div class="car-option-text">
        Dossier
      </div>
    </button>
    <button
      v-if="car.is_deleted"
      class="car-option-button"
      @click="rescheduleCar"
    >
      <Pencil
        size="18"
        class="car-option-icon"
      />
      <div class="car-option-text">
        Reprogrammer
      </div>
    </button>
    <button
      v-if="!car.is_deleted"
      class="car-option-button"
      @click="changeCalendarDate"
    >
      <Calendar
        size="18"
        class="car-option-icon"
      />
      <div class="car-option-text">
        Replanifier
      </div>
    </button>
    <button
      v-if="!car.is_deleted"
      class="car-option-button"
      @click="delayWork"
    >
      <ArrowRight
        size="18"
        class="car-option-icon"
      />
      <div class="car-option-text">
        Reporter du travail
      </div>
    </button>
    <button
      v-if="isDelayedWork && !car.is_deleted"
      class="car-option-button"
      @click="calendarStore.deleteDelayedWork(props.object.work)"
    >
      <X
        size="18"
        class="car-option-icon"
      />
      <div class="car-option-text">
        Annuler le report
      </div>
    </button>
    <button
      v-if="!isDelayedWork && !car.is_deleted"
      class="car-option-button"
      @click="carStore.openDeletionDialog(car)"
    >
      <X
        size="18"
        class="car-option-icon"
      />
      <div class="car-option-text">
        Annuler le rendez-vous
      </div>
    </button>
    <a
      v-if="!calendarStore.isHomepage"
      class="car-option-button"
      :href="`${calendarStore.baseUrl}/vehicule/en-charge/${car.id}/`"
    >
      <Folder
        size="18"
        class="car-option-icon"
      />
      <div class="car-option-text">
        Détails
      </div>
    </a>
    <a
      v-else
      class="car-option-button"
    >
      <Folder
        size="18"
        class="car-option-icon"
      />
      <div class="car-option-text">
        Détails
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../ui/static_src/ui/base/mixins";

.car-options {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .4rem;
  flex-direction: column;
  width: 100%;
  margin-top: .4rem;
}

.car-options-delimiter {
  width: 90%;
  height: 1px;
  background-color: var(--color-border-secondary);
}

.car-option-button {
  @include body;
  display: flex;
  align-items: center;
  height: 1.6rem;
  width: 100%;
}

.car-option-icon {
  color: var(--color-icon);
}

.car-option-text {
  opacity: .8;
}

.car-option-button:hover .car-option-text,
.car-option-button:hover .car-option-icon {
  opacity: 1;
  color: var(--color-icon-focus);
}
</style>
