import { orderBy } from "lodash-es"
import { defineStore } from "pinia"

import { getInsurancesGroups } from "../../../../insurances/static_src/insurances/services/api.js"
import { getCurrentMonday, getIsoString, getNextFriday } from "../../../../ui/static_src/ui/utils/date.js"
import { serializeCar, serializeDelayedWork } from "../serializers/car.js"
import {
  createCarInCharge,
  createClosure,
  createDelayedWork,
  createNote,
  deleteClosure,
  deleteDelayedWork,
  deleteNote,
  deleteReplacement,
  getCarsOfWeek,
  getReplacementCars,
  getWeekData,
  markFullWeek,
  patchClosure,
  updateCarInCharge,
  updateCarState,
  updateDelayedWork,
  updateNote,
  updateReplacement,
} from "../services/api.js"
import { getRepairingStateList, getStateDate, isFollowupComplete } from "../utils/cars.js"

const buildClosureBaseModel = (companyId) => {
  return {
    company_id: companyId, start_date: null, end_date: null,
  }
}

export const useCalendarStore = defineStore("calendar", {
  state: () => ({
    env: null,
    currentUser: null,
    cars: [],
    delayedWorks: [],
    isDragging: false,
    replacementCars: [],
    loadingData: true,
    currentWeekVisible: null,
    weeks: {},
    renderedDays: [],
    carInChargeModalOpen: false,
    replacementModalOpen: false,
    insuranceModalOpen: false,
    notificationsModalOpen: false,
    closureModalOpen: false,
    focusedClosure: null,
    closures: [],
    suggestedReplacements: [],
    replacementWeekSuggestionsModalOpen: false,
    replacementWeekSuggestionsMonday: null,
    replacementWeekSuggestionsCarsInCharge: [],
    insuranceGroups: [],
    notificationsCount: 0,
    isHomepage: false,
    filteredWeeks: [],
    hiddenCars: [],
    hiddenWorks: [],
    updatedCars: 0,
  }),
  getters: {
    getReplacementCarsCount() {
      return this.replacementCars.length
    },
    getCarsOfWeek: (state) => (monday) => {
      const friday = getNextFriday(monday)
      return state.cars.filter(car => new Date(getStateDate(car)) >= monday && new Date(getStateDate(car)) <= friday)
    },
    getDelayedWorksOfWeek: (state) => (monday) => {
      const friday = getNextFriday(monday)
      return state.delayedWorks.filter(work => new Date(work.date) >= monday && new Date(work.date) <= friday)
    },
    isFullWeek: (state) => (monday) => {
      return state.weeks[getIsoString(monday)]?.data?.full
    },
    isClosed: (state) => (day) => {
      return state.closures.some(closure => {
        const endDate = new Date(closure.end_date)
        endDate.setHours(23, 59, 59, 999)
        return new Date(closure.start_date).getTime() <= day.getTime() && endDate.getTime() >= day.getTime()
      })
    },
    getCarsOfDay: (state) => (day) => {
      return state.cars.filter(car => new Date(getStateDate(car)).getTime() === day.getTime())
    },
    getDelayedWorksOfDay: (state) => (day) => {
      return state.delayedWorks.filter(work => new Date(work.date).getTime() === day.getTime())
    },
    getDayCapacity: (state) => (day) => {
      const monday = new Date(day)
      monday.setDate(day.getDate() - day.getDay() + 1)
      return state.weeks[getIsoString(monday)].data.dailyWorkCapacity[getIsoString(day)]
    },
    baseUrl: (state) => {
      return `/${state.env?.company_slug}`
    },
  },
  actions: {
    async init(env) {
      // env
      this.env = env
      this.currentUser = JSON.parse(env.user)
      this.isHomepage = env.user === "{}"

      // current week
      const monday = getCurrentMonday(this.env.current_date_iso)
      this.currentWeekVisible = monday

      if (this.isHomepage) return this.initHomepage(monday)

      // load replacement cars count
      const { data } = await getReplacementCars(this.env.company_pk)
      this.replacementCars = data

      // load cars and delayed works
      const currentWeek = await this.loadWeekData(monday)

      return currentWeek
    },
    async loadWeekData(monday) {
      const mondayString = getIsoString(monday)
      this.weeks[mondayString] = { monday, data: null }

      const { data: { week_data: weekData, cars, delayed_works: delayedWorks, closures } } = await getWeekData(mondayString, this.env.company_pk)

      this.cars.push(...cars.map(serializeCar))
      this.delayedWorks.push(...delayedWorks.map(serializeDelayedWork))
      const week = {
        monday,
        notes: weekData.notes,
        dailyWorkCapacity: weekData.works_capacity_per_day,
        full: weekData.full,
        publicHolidays: weekData.public_holidays,
      }
      this.closures = [
        ...this.closures,
        ...closures
          .filter(closure => !this.closures.some(c => c.id === closure.id))
          .map(closure => ({ ...closure, start_date: new Date(closure.start_date), end_date: new Date(closure.end_date) })),
      ]
      this.weeks[mondayString].data = week
      return this.weeks[mondayString]
    },
    initHomepage(monday) {
      const mondayString = getIsoString(monday)
      this.weeks[mondayString] = { monday, data: null }
      this.cars = window.HOMEPAGE_CALENDAR_CARS.map(serializeCar)
      this.delayedWorks = window.HOMEPAGE_CALENDAR_DELAYED_WORKS.map(serializeDelayedWork)
      this.replacementCars = window.HOMEPAGE_CALENDAR_REPLACEMENT_CARS
      const week = {
        monday,
        notes: window.HOMEPAGE_CALENDAR_WEEK.notes,
        dailyWorkCapacity: window.HOMEPAGE_CALENDAR_WEEK.works_capacity_per_day,
        full: window.HOMEPAGE_CALENDAR_WEEK.full,
        publicHolidays: window.HOMEPAGE_CALENDAR_WEEK.public_holidays,
      }
      this.weeks[mondayString].data = week
      this.loadingData = false
      return this.weeks[mondayString]
    },
    markFullWeek(monday, options) {
      const mondayString = getIsoString(monday)
      markFullWeek(mondayString, this.env.company_pk, options)
      this.weeks[mondayString].data.full = options.full
    },
    async createNote(monday) {
      const note = {
        author: this.currentUser.id,
        text: "",
        company: this.env.company_pk,
        week: getIsoString(monday),
      }
      const { data } = await createNote(note)
      this.weeks[getIsoString(monday)].data.notes.push(data)
    },
    updateNote(monday, noteId, text) {
      const note = this.weeks[getIsoString(monday)].data.notes.find(note => note.id === noteId)
      note.text = text
      updateNote({ ...note, author: this.currentUser.id })
    },
    deleteNote(monday, noteId) {
      const week = this.weeks[getIsoString(monday)].data
      const noteIndex = week.notes.findIndex(note => note.id === noteId)
      week.notes.splice(noteIndex, 1)
      deleteNote({ id: noteId })
    },
    async updateCarState(object, newState, newRepairingStates) {
      const newRepairingStatesValues = newRepairingStates
        ? {
            sheet_metal_state: getRepairingStateList().find(s => s.label === newRepairingStates[0]).value,
            painting_state: getRepairingStateList().find(s => s.label === newRepairingStates[1]).value,
            mechanic_state: getRepairingStateList().find(s => s.label === newRepairingStates[2]).value,
          }
        : null
      const { data } = await updateCarState({ id: object.type === "car" ? object.car.id : object.work.car.id, state: newState, ...newRepairingStatesValues })
      if (object.type === "car") {
        const car = object.car
        const index = this.cars.findIndex(c => c.id === car.id)
        this.cars.splice(index, 1)
        this.cars.push(serializeCar(data))
      } else {
        this.delayedWorks.find(w => w.id === object.work.id).car = serializeCar(data)
      }
      this.updatedCars += 1
    },
    async createCarInCharge(car) {
      if (car.state !== "breakage") car.breakage_date = null
      if (car.state !== "expertise") car.expertise_date = null
      if (car.state !== "waiting") car.waiting_date = null
      if (["breakage", "expertise", "waiting"].includes(car.state)) car.start_work = null
      const { data } = await createCarInCharge({ ...car, insurance_ids: car.insurances.map(i => i.id) })
      this.cars.push(serializeCar(data))
      return this.cars.find(c => c.id === data.id)
    },
    async updateCarInCharge(car) {
      const { data } = await updateCarInCharge({ ...car, insurance_ids: car.insurances.map(i => i.id) })
      const indexCar = this.cars.findIndex(c => c.id === car.id)
      if (indexCar !== -1) {
        this.cars.splice(indexCar, 1)
        this.cars.push(serializeCar(data))
      }

      const work = this.delayedWorks.find(w => w.car.id === car.id && w.is_last)
      if (work) {
        const indexWork = this.delayedWorks.findIndex(w => w.id === work.id)
        this.delayedWorks.splice(indexWork, 1)
        this.delayedWorks.push({ ...work, car: data })
      }
      this.updatedCars += 1
      return this.cars.find(c => c.id === data.id)
    },
    async updateDelayedWork(work) {
      const { data } = await updateDelayedWork({ ...work, car_id: work.car.id })
      const index = this.delayedWorks.findIndex(w => w.id === work.id)
      this.delayedWorks.splice(index, 1)
      this.delayedWorks.push(serializeDelayedWork(data))
      return this.delayedWorks.find(w => w.id === data.id)
    },
    async rescheduleCarInCharge(car) {
      car.is_deleted = false
      await this.updateCarInCharge(car)
    },
    addNewDraggingCarStateDate(carId, day) {
      const carState = this.cars.find(c => c.id === carId).state
      switch (carState) {
        case "breakage":
          this.cars.find(c => c.id === carId).breakage_date = new Date(day)
          break
        case "expertise":
          this.cars.find(c => c.id === carId).expertise_date = new Date(day)
          break
        case "waiting":
          this.cars.find(c => c.id === carId).waiting_date = new Date(day)
          break
        default:
          this.cars.find(c => c.id === carId).start_work = new Date(day)
      }
    },
    removeDraggingCarStateDate(carId) {
      const carState = this.cars.find(c => c.id === carId).state
      switch (carState) {
        case "breakage":
          this.cars.find(c => c.id === carId).breakage_date = null
          break
        case "expertise":
          this.cars.find(c => c.id === carId).expertise_date = null
          break
        case "waiting":
          this.cars.find(c => c.id === carId).waiting_date = null
          break
        default:
          this.cars.find(c => c.id === carId).start_work = null
      }
    },
    addNewDraggingWorkDate(workId, day) {
      this.delayedWorks.find(w => w.id === workId).date = new Date(day)
    },
    removeDraggingWorkDate(workId) {
      this.delayedWorks.find(w => w.id === workId).date = null
    },
    async createDelayedWork(work) {
      const car = work.car
      const { data } = await createDelayedWork({ ...work, car_id: work.car.id, is_last: true })
      this.delayedWorks.push(serializeDelayedWork(data))

      const delayedWork = this.delayedWorks.find(w => w.car.id === work.car.id && w.is_last && w.id !== data.id)
      if (delayedWork) {
        delayedWork.sheet_metal_work_hours -= Math.min(delayedWork.sheet_metal_work_hours, work.sheet_metal_work_hours)
        delayedWork.painting_hours -= Math.min(delayedWork.painting_hours, work.painting_hours)
        delayedWork.mechanic_hours -= Math.min(delayedWork.mechanic_hours, work.mechanic_hours)
        const delayedWorkBefore = await this.updateDelayedWork({ ...delayedWork, is_last: false })
        const currentDelayedWork = this.delayedWorks.find(w => w.id === data.id)
        const targetWork = currentDelayedWork.car.delayed_works.find(w => w.id === delayedWorkBefore.id)
        Object.assign(targetWork, { ...delayedWork, date: delayedWork.date.toISOString() })
      }

      if (car.delayed_works.length === 0) {
        const storedCar = this.cars.find(c => c.id === car.id)
        storedCar.sheet_metal_work_hours -= Math.min(car.sheet_metal_work_hours, work.sheet_metal_work_hours)
        storedCar.painting_hours -= Math.min(car.painting_hours, work.painting_hours)
        storedCar.mechanic_hours -= Math.min(car.mechanic_hours, work.mechanic_hours)
        await this.updateCarInCharge({ ...storedCar, insurance_ids: car.insurances.map(i => i.id) })
      }

      return this.delayedWorks.find(w => w.id === data.id)
    },
    async deleteDelayedWork(work) {
      const car = work.car
      const carHasReplacement = car.replacements.length > 0
      deleteDelayedWork({ id: work.id })
      const index = this.delayedWorks.findIndex(w => w.id === work.id)
      this.delayedWorks.splice(index, 1)

      car.delayed_works = car.delayed_works.filter(w => w.id !== work.id)
      if (car.delayed_works.length === 0) {
        car.sheet_metal_work_hours += work.sheet_metal_work_hours
        car.painting_hours += work.painting_hours
        car.mechanic_hours += work.mechanic_hours
        if (carHasReplacement) {
          const newReplacement = {
            ...car.replacements[0],
            end_date: getIsoString(getNextFriday(new Date(car.replacements[0].start_date))),
          }
          updateReplacement(newReplacement).then(() => this.updateCarInCharge({ ...car, insurance_ids: car.insurances.map(i => i.id) }))
        } else this.updateCarInCharge({ ...car, insurance_ids: car.insurances.map(i => i.id) })
      } else {
        const lastWork = orderBy(car.delayed_works, "date", "desc")[0]
        lastWork.sheet_metal_work_hours += work.sheet_metal_work_hours
        lastWork.painting_hours += work.painting_hours
        lastWork.mechanic_hours += work.mechanic_hours
        if (carHasReplacement) {
          const newReplacement = {
            ...car.replacements[0],
            end_date: getIsoString(getNextFriday(new Date(lastWork.date))),
          }
          await updateReplacement(newReplacement)
        }
        await this.updateDelayedWork({ ...lastWork, is_last: true, car: { id: car.id } })
      }
    },
    deleteReplacement(replacement) {
      deleteReplacement(replacement)

      const carIndex = this.cars.findIndex(c => c.id === replacement.car_incharge.id)
      const delayedWorkIndex = this.delayedWorks.findIndex(w => w.car.id === replacement.car_incharge.id)
      if (carIndex !== -1) this.cars[carIndex].replacements = []
      if (delayedWorkIndex !== -1) this.delayedWorkIndex[delayedWorkIndex].car.replacements = []
    },
    filterFolderOfWeek(monday) {
      const friday = getNextFriday(monday)
      this.filteredWeeks.push(getIsoString(monday))
      this.cars.forEach(car => {
        if (
          ((new Date(getStateDate(car)) >= monday && new Date(getStateDate(car)) <= friday) &&
          isFollowupComplete(car)) ||
          car.is_deleted
        ) {
          this.hiddenCars.push(car)
        }
      })
      this.delayedWorks.forEach(work => {
        if (
          ((new Date(work.date) >= monday && new Date(work.date) <= friday) &&
          isFollowupComplete(work.car)) ||
          work.car.is_deleted
        ) {
          this.hiddenWorks.push(work)
        }
      })
    },
    unfilterFolderOfWeek(monday) {
      const friday = getNextFriday(monday)
      this.filteredWeeks = this.filteredWeeks.filter(week => week !== getIsoString(monday))
      this.cars.forEach(car => {
        if (
          ((new Date(getStateDate(car)) >= monday && new Date(getStateDate(car)) <= friday) &&
          isFollowupComplete(car)) ||
          car.is_deleted
        ) {
          const index = this.hiddenCars.findIndex(c => c.id === car.id)
          if (index !== -1) this.hiddenCars.splice(index, 1)
        }
      })
      this.delayedWorks.forEach(work => {
        if (
          ((new Date(work.date) >= monday && new Date(work.date) <= friday) &&
          isFollowupComplete(work.car)) ||
          work.car.is_deleted
        ) {
          const index = this.hiddenWorks.findIndex(w => w.id === work.id)
          if (index !== -1) this.hiddenWorks.splice(index, 1)
        }
      })
    },
    isCarHidden(car) {
      return this.hiddenCars.some(c => c.id === car.id)
    },
    isWorkHidden(work) {
      return this.hiddenWorks.some(w => w.id === work.id)
    },
    async openReplacementWeekSuggestionsModal(monday) {
      this.replacementWeekSuggestionsMonday = monday
      const { data: insuranceGroups } = await getInsurancesGroups()
      this.insuranceGroups = insuranceGroups
      const { data: cars } = await getCarsOfWeek(getIsoString(monday), this.env.company_pk, { need_replacement_only: true })
      this.replacementWeekSuggestionsCarsInCharge = cars.map(serializeCar)
      this.replacementWeekSuggestionsModalOpen = true
    },
    updateSuggestedReplacement(replacement) {
      const event = new CustomEvent("update-suggested-replacement", { detail: { replacement } })
      document.dispatchEvent(event)
    },
    async refreshCars(monday) {
      const { data } = await getCarsOfWeek(getIsoString(monday), this.env.company_pk)
      this.cars = this.cars.map(car => {
        const newCar = data.find(c => c.id === car.id)
        return newCar ? serializeCar(newCar) : car
      })
    },
    getClosure(day) {
      return this.closures.find(closure => {
        const endDate = new Date(closure.end_date)
        endDate.setHours(23, 59, 59, 999)
        return new Date(closure.start_date).getTime() <= day.getTime() && endDate.getTime() >= day.getTime()
      })
    },
    openClosureModal(day = null) {
      this.focusedClosure = buildClosureBaseModel(this.env.company_pk)
      if (day) {
        const closure = this.getClosure(day)
        Object.assign(this.focusedClosure, closure)
      }
      this.closureModalOpen = true
    },
    async addClosure(closure) {
      const { data } = await createClosure(closure)
      this.closures.push({ ...data, start_date: new Date(data.start_date), end_date: new Date(data.end_date) })
      this.closureModalOpen = false
    },
    async updateClosure(closure) {
      const { data } = await patchClosure(closure)
      const index = this.closures.findIndex(c => c.id === closure.id)
      this.closures.splice(index, 1, { ...data, start_date: new Date(data.start_date), end_date: new Date(data.end_date) })
      this.closureModalOpen = false
    },
    async deleteClosure(closure) {
      const index = this.closures.findIndex(c => c.id === closure.id)
      this.closures.splice(index, 1)
      await deleteClosure(closure.id)
    },
  },
})
