<script setup>
import { Form } from "@primevue/forms"
import { Calendar, Clock } from "lucide-vue-next"
import { storeToRefs } from "pinia"
import { useToast } from "primevue"
import Checkbox from "primevue/checkbox"
import DatePicker from "primevue/datepicker"
import Dialog from "primevue/dialog"
import FloatLabel from "primevue/floatlabel"
import InputNumber from "primevue/inputnumber"
import Select from "primevue/select"
import { ref } from "vue"

import { getIsoString } from "../../../../../../ui/static_src/ui/utils/date.js"
import { TOAST_DURATION } from "../../../../../../ui/static_src/ui/utils/toast.js"
import { getStaff, patchContract, postContract } from "../../../services/api.js"
import useStaffStore from "../../../stores/staff.js"

const staff = useStaffStore()
const toast = useToast()
const loading = ref(false)
const { focusedContract, isContractModalOpen, focusedStaff, staffObject } = storeToRefs(staff)

const weekDayValue = (index) => {
  const halfDay = index % 2 === 0 ? "morning" : "afternoon"
  const day = ["monday", "tuesday", "wednesday", "thursday", "friday"][Math.floor(index / 2)]
  return focusedContract.value.week_days[day][halfDay]
}

const updateModel = (key, value) => {
  focusedContract.value[key] = value
}

const updateWeekDay = (index, event) => {
  event.preventDefault()
  event.stopPropagation()
  const halfDay = index % 2 === 0 ? "morning" : "afternoon"
  const day = ["monday", "tuesday", "wednesday", "thursday", "friday"][Math.floor(index / 2)]
  focusedContract.value.week_days[day][halfDay] = !focusedContract.value.week_days[day][halfDay]
}

const contractValues = {
  CDD: "cdd",
  CDI: "cdi",
  Indépendant: "freelance",
}

const contractNames = {
  cdd: "CDD",
  cdi: "CDI",
  freelance: "Indépendant",
}

const resolver = () => {
  const errors = { type: [], weekly_hours: [], start_date: [], end_date: [] }
  if (!focusedContract.value.type) errors.type.push({ type: "required" })
  if (!focusedContract.value.weekly_hours) errors.weekly_hours.push({ type: "required" })
  if (!focusedContract.value.start_date) errors.start_date.push({ type: "required" })
  if (!focusedContract.value.end_date && focusedContract.value.type !== "cdi") errors.end_date.push({ type: "required" })
  return { errors }
}

const onFormSubmit = async({ valid }) => {
  if (!valid) return
  loading.value = true
  const contractMessage = focusedContract.value.type === "freelance" ? "Le contrat en indépendant" : `Le ${contractNames[focusedContract.value.type]}`
  const contract = { ...focusedContract.value, staff_id: focusedStaff.value.id, start_date: getIsoString(focusedContract.value.start_date), end_date: focusedContract.value.end_date ? getIsoString(focusedContract.value.end_date) : null }
  if (!focusedContract.value.id) {
    await postContract(contract)
    toast.add({
      severity: "success",
      summary: "Contrat ajouté",
      detail: `${contractMessage} a bien été ajouté`,
      life: TOAST_DURATION,
    })
  } else {
    await patchContract(contract)
    toast.add({
      severity: "success",
      summary: "Compagnon modifié",
      detail: `${contractMessage} a bien été modifié`,
      life: TOAST_DURATION,
    })
  }
  if (staffObject.value?.id) {
    const { data } = await getStaff(staffObject.value.id)
    staffObject.value = data
  } else await staff.fetchStaff()
  loading.value = false
  staff.isContractModalOpen = false
}
</script>

<template>
  <Dialog
    v-model:visible="isContractModalOpen"
    :header="focusedContract?.id ? 'Modifier le contrat' : 'Ajouter un contrat'"
    modal
  >
    <Form
      :resolver="resolver"
      @submit="onFormSubmit"
    >
      <div class="contract-form-block">
        <FloatLabel variant="on">
          <Select
            id="type"
            name="type"
            :model-value="contractNames[focusedContract.type]"
            :options="['CDI', 'CDD', 'Indépendant']"
            style="width: 12.5rem;"
            @value-change="(value) => updateModel('type', contractValues[value])"
          />
          <label for="type">Type de contrat</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <InputNumber
            id="weekly_hours"
            name="weekly_hours"
            :model-value="focusedContract.weekly_hours"
            style="width: 12.5rem;"
            @update:model-value="(value) => updateModel('weekly_hours', value)"
          />
          <label for="weekly_hours">Volume horaire par semaine (h)</label>
        </FloatLabel>
      </div>
      <div class="contract-form-block -margin-top -column">
        <div class="contract-form-subtitle">
          <Clock size="24" />
          Jours de travail
        </div>
        <button
          v-for="(day, index) in ['Lundi matin', 'Lundi après-midi', 'Mardi matin', 'Mardi après-midi', 'Mercredi matin', 'Mercredi après-midi', 'Jeudi matin', 'Jeudi après-midi', 'Vendredi matin', 'Vendredi après-midi']"
          :key="index"
          class="contract-form-checkbox"
          @click="updateWeekDay(index, $event)"
        >
          <Checkbox
            :model-value="weekDayValue(index)"
            :input-id="`week-half-day-${index}`"
            :name="`week-half-day-${index}`"
            :binary="true"
          />
          <label
            :for="`week-half-day-${index}`"
            style="cursor: pointer; color: var(--color-text-secondary);"
          >{{ day }}</label>
        </button>
      </div>
      <div class="contract-form-block -margin-top -column">
        <div class="contract-form-subtitle">
          <Calendar size="24" />
          Dates
        </div>
        <div class="contract-form-block">
          <FloatLabel variant="on">
            <DatePicker
              id="start_date"
              name="start_date"
              :model-value="focusedContract.start_date"
              style="width: 12.5rem;"
              date-format="dd/mm/yy"
              @update:model-value="(value) => updateModel('start_date', value)"
            />
            <label for="start_date">Date de début</label>
          </FloatLabel>
          <FloatLabel variant="on">
            <DatePicker
              id="end_date"
              name="end_date"
              :variant="focusedContract.type === 'cdi' && !focusedContract.end_date ? 'filled' : ''"
              :model-value="focusedContract.end_date"
              date-format="dd/mm/yy"
              style="width: 12.5rem;"
              @update:model-value="(value) => updateModel('end_date', value)"
            />
            <label for="end_date">Date de fin</label>
          </FloatLabel>
        </div>
      </div>
      <div class="contract-form-validate">
        <i
          v-if="loading"
          class="pi pi-spin pi-cog loading-icon"
        />
        <button
          v-else
          class="contract-form-button"
          type="submit"
        >
          {{ focusedContract?.id ? 'Modifier' : 'Ajouter' }}
        </button>
      </div>
    </Form>
  </Dialog>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.contract-form-block {
  display: flex;
  gap: 1rem;
  margin-top: .4rem;

  &.-margin-top {
    margin-top: 1rem;
  }

  &.-column {
    flex-direction: column;
  }
}

.contract-form-subtitle {
  @include subtitle;
  display: flex;
  align-items: center;
  gap: .4rem;
  margin-top: .4rem;
}

.contract-form-validate {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.contract-form-button {
  @include body;
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: .4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--color-button-bg-submit-full-hover);
  }
}

.contract-form-checkbox {
  display: flex;
  align-items: center;
  gap: .6rem;
  width: fit-content;
  padding-inline: 0;
  padding-block: 0;

  &:hover {
    background-color: var(--content-bg--color-lighter);
  }
}
</style>
