import { createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "delete-replacement-container" }
const _hoisted_2 = { class: "replacement-dates" }
const _hoisted_3 = { class: "replacement-date" }
const _hoisted_4 = { class: "replacement-date" }
const _hoisted_5 = { class: "replacement-details" }
const _hoisted_6 = { class: "replacement-car" }
const _hoisted_7 = { class: "replacement-car-text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(['replacement', { '-conflict': $props.replacement.conflict }]),
    onClick: $setup.updateReplacement
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("button", {
        class: "delete-replacement-button",
        onClick: _cache[0] || (_cache[0] = (event) => $setup.deleteReplacement($props.replacement, event))
      }, [
        _createVNode($setup["X"], { size: "18" })
      ])), [
        [
          _directive_tooltip,
          { value: 'Supprimer le remplacement', showDelay: 500 },
          void 0,
          { right: true }
        ]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($setup.getFullDate($props.replacement.start_date)), 1 /* TEXT */),
      _createVNode($setup["ArrowBigRight"], { class: "replacement-date" }),
      _createElementVNode("div", _hoisted_4, _toDisplayString($setup.getFullDate($props.replacement.end_date)), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString($props.replacement.replacement_car.registration) + " - " + _toDisplayString($props.replacement.replacement_car.brand) + " - " + _toDisplayString($props.replacement.replacement_car.model), 1 /* TEXT */)
      ]),
      _createVNode($setup["InsuranceGroups"], {
        "insurance-groups": $props.replacement.replacement_car.insurance_groups
      }, null, 8 /* PROPS */, ["insurance-groups"])
    ])
  ], 2 /* CLASS */))
}