<script setup>
import { useDebounceFn } from "@vueuse/core"
import { asyncComputed } from "@vueuse/core/index.cjs"
import { orderBy } from "lodash-es"
import { ArrowBigRight, Check, TriangleAlert, X } from "lucide-vue-next"
import AutoComplete from "primevue/autocomplete"
import Checkbox from "primevue/checkbox"
import DatePicker from "primevue/datepicker"
import FloatLabel from "primevue/floatlabel"
import { ref, watch } from "vue"

import CarName from "./CarName.vue"
import ReplacementTimeline from "./ReplacementTimeline.vue"

import { getCurrentMonday, getDatetimeString, getIsoString, getShortDate } from "../../../../../ui/static_src/ui/utils/date.js"
import { checkReplacementConflict, getReplacementCarsSuggestions } from "../../services/api.js"
import { aggregateSuggestedReplacements } from "../../utils/replacement.js"

const emit = defineEmits(["update:modelValue"])

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({ start_date: "", end_date: "", replacement_car: null, finished: false }),
  },
  handleValidate: {
    type: Function,
    required: true,
  },
  focusedCar: {
    type: Object,
    required: true,
  },
  minDate: {
    type: Date,
    default: new Date(),
  },
  additionalReplacements: {
    type: Array,
    default: () => [],
  },
})

const query = ref("")
const conflictReplacements = ref([])
const conflictMaintenanceTimes = ref([])
const autoCompleteRef = ref(null)

const refreshSuggestions = useDebounceFn(async(monday, query, insurances) => {
  const { data } = await getReplacementCarsSuggestions({ monday, query, insurances })
  return orderBy(aggregateSuggestedReplacements(data, props.additionalReplacements), ["score"], ["desc"])
}, 500)

const replacementsSuggestions = asyncComputed(async() => {
  if (!props.focusedCar.id) return []
  return await refreshSuggestions(
    getIsoString(getCurrentMonday(props.minDate)),
    query.value,
    props.focusedCar.insurances.map(insurance => insurance.id),
  )
}, [])

const onFocus = () => {
  autoCompleteRef.value.show()
}

const handleSelect = (event) => {
  query.value = `${event.value.registration} - ${event.value.brand} - ${event.value.model}`
  emit("update:modelValue", { ...props.modelValue, replacement_car: event.value })
  replacementsSuggestions.value = []
}

const checkConflict = async() => {
  if (!props.modelValue.replacement_car) return
  // eslint-disable-next-line camelcase
  const { data: { conflicting_replacements, conflicting_maintenance_times } } = await checkReplacementConflict({
    startDate: getDatetimeString(props.modelValue.start_date),
    endDate: getDatetimeString(props.modelValue.end_date),
    replacementCarId: props.modelValue.replacement_car.id,
  })

  // eslint-disable-next-line camelcase
  conflictReplacements.value = conflicting_replacements.filter(replacement => replacement.id !== props.modelValue?.id)
  // eslint-disable-next-line camelcase
  conflictMaintenanceTimes.value = conflicting_maintenance_times
}

const updateModel = (key, value) => {
  emit("update:modelValue", { ...props.modelValue, [key]: value })
}

const onInput = () => {
  if (!query.value) setTimeout(autoCompleteRef.value.show, 100)
}

watch(() => props.modelValue, () => {
  query.value = props.modelValue.replacement_car ? `${props.modelValue.replacement_car.registration} - ${props.modelValue.replacement_car.brand} - ${props.modelValue.replacement_car.model}` : ""
  checkConflict()
})
</script>

<template>
  <div class="replacement-container">
    <FloatLabel variant="on">
      <AutoComplete
        id="car_replacement"
        ref="autoCompleteRef"
        v-model="query"
        style="width: 30rem;"
        :suggestions="replacementsSuggestions"
        :option-label="option => option.registration + ' - ' + option.brand + ' - ' + option.model"
        @input="onInput"
        @focus="onFocus"
        @item-select="handleSelect"
      >
        <template #header>
          <div class="replacement-car-header">
            <div style="width: 15rem;">
              Véhicules
            </div>
            <div style="width: 5rem; text-align: center;">
              Groupes
            </div>
            <div style="width: 10rem; text-align: center;">
              Prise en charge
            </div>
            <div style="width: 10rem; text-align: center;">
              Indisponibilité
            </div>
            <div style="width: 15rem; text-align: center;">
              Raison
            </div>
          </div>
        </template>
        <template #option="{ option }">
          <div class="replacement-car-selecter">
            <span style="width: 15rem;">
              {{ option.registration }} - {{ option.brand }} - {{ option.model }}
            </span>
            <div
              class="replacement-car-insurance-groups"
              style="width: 5rem; text-align: center;"
            >
              <div
                v-for="(group, index) in option.insurance_groups"
                :key="index"
                v-tooltip.bottom="group.name"
                class="car__card-state"
                :style="`background-color: ${group.color}`"
              />
            </div>
            <div
              class="replacement-car-valid"
              style="width: 10rem;"
            >
              <Check
                v-if="option.score === 1"
                size="18"
                class="replacement-car-valid-icon"
              />
              <X
                v-else
                size="18"
                class="replacement-car-invalid-icon"
              />
            </div>
            <div
              v-if="option.unavailable_since"
              class="replacement-car-borrow-dates"
              style="width: 10rem;"
            >
              <div class="replacement-car-borrow-date">
                {{ getShortDate(new Date(option.unavailable_since)) }}
              </div>
              <ArrowBigRight
                size="18"
                class="replacement-car-borrow-icon"
              />
              <div class="replacement-car-borrow-date">
                {{ getShortDate(new Date(option.unavailable_up)) }}
              </div>
            </div>
            <div
              class="replacement-car-borrow-dates"
              style="width: 15rem;"
            >
              <div class="replacement-car-borrow-car">
                {{ option.unavailable_reason }}{{ option.replacement_car_incharge ? ' par ' : '' }}
                <CarName
                  v-if="option.replacement_car_incharge"
                  :car-in-charge="option.replacement_car_incharge"
                  only-owner-last-name
                />
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          <div class="replacement-car-empty">
            Aucun véhicule ne correspond à votre recherche
          </div>
        </template>
      </AutoComplete>
      <label for="car_replacement">Véhicule de remplacement</label>
    </FloatLabel>
    <div class="replacement-car-dates">
      <FloatLabel variant="on">
        <DatePicker
          id="replacement-start-date"
          show-time
          :model-value="modelValue.start_date"
          style="width: 14.5rem;"
          date-format="dd/mm/yy"
          hour-format="24"
          :min-date="minDate"
          @update:model-value="(value) => updateModel('start_date', value)"
        />
        <label for="replacement-start-date">Début du prêt</label>
      </FloatLabel>
      <FloatLabel variant="on">
        <DatePicker
          id="replacement-end-date"
          show-time
          :model-value="modelValue.end_date"
          style="width: 14.5rem;"
          :min-date="minDate"
          date-format="dd/mm/yy"
          hour-format="24"
          @update:model-value="(value) => updateModel('end_date', value)"
        />
        <label for="replacement-end-date">Fin du prêt</label>
      </FloatLabel>
    </div>
    <button
      v-if="modelValue.id"
      class="checkbox"
      @click="updateModel('finished', !modelValue.finished), $event.stopPropagation(), $event.preventDefault()"
    >
      <Checkbox
        :model-value="modelValue.finished"
        input-id="finished"
        :binary="true"
        class="checkbox-replacement"
      />
      <label
        for="finished"
        style="cursor: pointer; color: var(--color-text-secondary);"
      >Véchiule rendu</label>
    </button>
    <div
      v-if="conflictReplacements.length > 0"
      class="conflict-replacements"
    >
      <div class="conflict-message">
        <TriangleAlert size="18" />
        Le véhicule de remplacement est emprunté par {{ conflictReplacements.length === 1 ? "un" : conflictReplacements.length }} autre{{ conflictReplacements.length === 1 ? '':'s' }} client{{ conflictReplacements.length === 1 ? '':'s' }} aux mêmes dates
      </div>
      <div
        v-for="conflictReplacement in conflictReplacements"
        :key="conflictReplacement.id"
        class="conflict-replacements-list"
      >
        <div class="conflict-replacement">
          <ReplacementTimeline
            :replacement="conflictReplacement"
            with-time
            with-owner
          />
        </div>
      </div>
    </div>
    <div
      v-if="conflictMaintenanceTimes.length > 0"
      class="conflict-replacements"
    >
      <div class="conflict-message">
        <TriangleAlert size="18" />
        Le véhicule de remplacement est en maintenance aux mêmes dates
      </div>
    </div>
    <div class="replacement-car-form-validate">
      <button
        class="replacement-car-form-button"
        :disabled="!modelValue.start_date || !modelValue.end_date || !modelValue.replacement_car || conflictReplacements.length > 0 || conflictMaintenanceTimes.length > 0"
        @click="handleValidate"
      >
        Valider
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.replacement-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: .4rem;
}

.replacement-car-selecter {
  @include body;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.replacement-car-insurance-groups {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .2rem;
}

.replacement-car-borrow-dates {
  display: flex;
  flex-direction: row;
  gap: .4rem;
  align-items: center;
  justify-content: center;
  color: var(--color-text-warning);
}

.replacement-car-borrow-icon {
  color: var(--color-text-warning);
  fill: var(--color-text-warning);
}

.replacement-car-header {
  @include subtitle;
  display: flex;
  width: calc(100% - 1.5rem);
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: .4px solid var(--color-border-secondary);
  margin-left: .75rem;
}

.replacement-car-valid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.replacement-car-valid-icon {
  color: var(--color-text-success-light)
}

.replacement-car-invalid-icon {
  color: var(--color-text-warning)
}

.replacement-car-empty {
  @include body;
}

.replacement-car-dates {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 30rem;
}

.replacement-car-form-validate {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.4rem;
}

.replacement-car-form-button {
  @include body;
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: .4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--color-button-bg-submit-full-hover);
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: .6rem;
  width: fit-content;
  padding-inline: 0;
  padding-block: 0;

  &:hover {
    background-color: var(--content-bg--color-lighter);
  }
}

.checkbox-replacement {
  width: .8rem;
  height: .8rem;
}

@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.conflict-replacements {
  display: flex;
  flex-direction: column;
  gap: .4rem;
  animation: fadeSlideIn 0.5s ease-out;
}

.conflict-message {
  @include body;
  color: var(--color-text-warning);
  display: flex;
  align-items: center;
  gap: .4rem;
}

.conflict-replacement {
  display: flex;
  flex-direction: column;
  border: .1px solid var(--color-text-quaternary-secondary);
  padding: .4rem;
  border-radius: var(--radius);
}

.replacement-car-borrow-car {
  @include body;
  display: flex;
  align-items: center;
  gap: .4rem;
}
</style>
