import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "calendar-week-day-name" }
const _hoisted_2 = {
  key: 0,
  class: "calendar-week-day-work"
}
const _hoisted_3 = {
  key: 1,
  class: "calendar-week-day-cars"
}
const _hoisted_4 = {
  key: 2,
  class: "calendar-week-holidays-message"
}
const _hoisted_5 = {
  key: 0,
  class: "calendar-closed-week-day-buttons"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("div", {
    ref: "dayRef",
    class: _normalizeClass(['calendar-week-day', {'-limit': $setup.isOutOfDueDate }, { '-focusable': $setup.carStore.draggingCar || $setup.carStore.draggingWork && !$setup.isInPast }, { '-past': $setup.isInPast }]),
    onClick: $setup.selectDay
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString($setup.getDateDayName($setup.props.day)), 1 /* TEXT */),
    (!$props.publicHolidayName && !$setup.isClosedDay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["CalendarChargeChart"], {
            work: $setup.dayWork,
            capacity: $setup.capacity,
            "font-size-value": "small",
            "delay-animation": $setup.props.delayAnimation
          }, null, 8 /* PROPS */, ["work", "capacity", "delay-animation"])
        ]))
      : _createCommentVNode("v-if", true),
    (!$props.publicHolidayName && !$setup.isClosedDay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.currentSlots.filter(slot => slot.display), (slot) => {
            return (_openBlock(), _createBlock($setup["CalendarCar"], {
              key: slot.type === 'car' ? slot.car.id : slot.work.car.id,
              object: slot
            }, null, 8 /* PROPS */, ["object"]))
          }), 128 /* KEYED_FRAGMENT */)),
          _createElementVNode("button", {
            class: "add-car-button",
            onClick: $setup.openCarInChargeForm
          }, [
            _createVNode($setup["PlusIcon"], {
              size: "20",
              class: "add-car-button-icon"
            })
          ])
        ]))
      : ($props.publicHolidayName)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.publicHolidayName), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(['calendar-closed-week-day', { '-focusable': $setup.calendarStore.currentUser.is_admin }])
          }, [
            _cache[2] || (_cache[2] = _createTextVNode(" Carrosserie fermée ")),
            ($setup.calendarStore.currentUser.is_admin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _withDirectives((_openBlock(), _createElementBlock("button", {
                    class: "calendar-closed-week-day-button",
                    onClick: _cache[0] || (_cache[0] = $event => ($setup.calendarStore.openClosureModal($setup.props.day)))
                  }, [
                    _createVNode($setup["Pen"], { size: "20" })
                  ])), [
                    [
                      _directive_tooltip,
                      { value: 'Modifier les horaires de fermeture', showDelay: 500 },
                      void 0,
                      { left: true }
                    ]
                  ]),
                  _withDirectives((_openBlock(), _createElementBlock("button", {
                    class: "calendar-closed-week-day-button -delete",
                    onClick: _cache[1] || (_cache[1] = $event => ($setup.openClosureDeletionModal($setup.calendarStore.getClosure($setup.props.day))))
                  }, [
                    _createVNode($setup["Trash2"], { size: "20" })
                  ])), [
                    [
                      _directive_tooltip,
                      { value: 'Supprimer la fermeture', showDelay: 500 },
                      void 0,
                      { left: true }
                    ]
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */))
  ], 2 /* CLASS */))
}