<script setup>
import { storeToRefs } from "pinia"
import { Dialog } from "primevue"

import WeekReplacementSuggestions from "../../../../../../cars/static_src/cars/components/_partials/WeekReplacementSuggestions.vue"

import { getCurrentMonday, getNextFriday } from "../../../../../../ui/static_src/ui/utils/date.js"
import { serializeCar } from "../../../serializers/car.js"
import { useCalendarStore } from "../../../stores/calendar.js"
import { useCarStore } from "../../../stores/car.js"
import { getStateDate } from "../../../utils/cars.js"

const calendar = useCalendarStore()
const car = useCarStore()
const {
  replacementWeekSuggestionsModalOpen,
  replacementWeekSuggestionsMonday,
  replacementWeekSuggestionsCarsInCharge,
  suggestedReplacements,
  insuranceGroups,
} = storeToRefs(calendar)

const openReplacementDialog = (carInCharge, replacement = null) => {
  calendar.replacementModalOpen = true
  const serializedCarInCharge = serializeCar(carInCharge)
  car.focusedCar = {
    ...serializedCarInCharge,
    replacements: replacement
      ? [replacement]
      : [
          {
            start_date: getStateDate(serializedCarInCharge),
            end_date: getNextFriday(getStateDate(serializedCarInCharge)),
            replacement_car: null,
            finished: false,
            isSuggestion: true,
            car_incharge: serializedCarInCharge,
          },
        ],
    delayed_works: [],
  }
}

const handleCreatedReplacements = () => {
  calendar.replacementWeekSuggestionsModalOpen = false
  calendar.refreshCars(getCurrentMonday(getStateDate(replacementWeekSuggestionsCarsInCharge.value[0])))
}
</script>

<template>
  <Dialog
    v-model:visible="replacementWeekSuggestionsModalOpen"
    header="Suggestions de remplacement avec l'IA"
    modal
    @after-hide="suggestedReplacements = []"
  >
    <WeekReplacementSuggestions
      v-model="suggestedReplacements"
      :monday="replacementWeekSuggestionsMonday"
      :insurance-groups="insuranceGroups"
      :open-replacement-dialog="openReplacementDialog"
      :cars-in-charge="replacementWeekSuggestionsCarsInCharge.filter(car => !car.is_deleted && car.need_replacement && car.replacements.length === 0)"
      @replacements-created="handleCreatedReplacements"
    />
  </Dialog>
</template>
