import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "replacement-header"
}
const _hoisted_2 = {
  key: 1,
  class: "replacement-header"
}
const _hoisted_3 = {
  key: 1,
  class: "replacement-empty"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "replacement-empty-icon" }
const _hoisted_6 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.calendarStore.replacementModalOpen,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => (($setup.calendarStore.replacementModalOpen) = $event)),
    modal: "",
    class: "replacement",
    onShow: $setup.refreshReplacement,
    onKeydown: _withKeys(_withModifiers($setup.handleEscape, ["stop"]), ["esc"])
  }, {
    header: _withCtx(() => [
      (!$setup.replacement.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[2] || (_cache[2] = _createElementVNode("span", null, "Fournir un véhicule de remplacement pour", -1 /* HOISTED */)),
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
            _withDirectives((_openBlock(), _createElementBlock("div", null, [
              _createTextVNode(_toDisplayString($setup.focusedCar.owner_last_name) + " | " + _toDisplayString($setup.focusedCar.model), 1 /* TEXT */)
            ])), [
              [
                _directive_tooltip,
                $setup.carNameTooltipOptions,
                void 0,
                { right: true }
              ]
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[4] || (_cache[4] = _createElementVNode("span", null, "Modifier l'emprunt pour", -1 /* HOISTED */)),
            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
            _withDirectives((_openBlock(), _createElementBlock("div", null, [
              _createTextVNode(_toDisplayString($setup.focusedCar.owner_last_name) + " | " + _toDisplayString($setup.focusedCar.model), 1 /* TEXT */)
            ])), [
              [
                _directive_tooltip,
                $setup.carNameTooltipOptions,
                void 0,
                { right: true }
              ]
            ])
          ]))
    ]),
    default: _withCtx(() => [
      ($setup.calendarStore.replacementCars.length > 0)
        ? (_openBlock(), _createBlock($setup["ReplacementForm"], {
            key: 0,
            modelValue: $setup.replacement,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.replacement) = $event)),
            "handle-validate": $setup.handleValidate,
            "min-date": $setup.minDate,
            "focused-car": $setup.focusedCar,
            "additional-replacements": $setup.calendarStore.suggestedReplacements
          }, null, 8 /* PROPS */, ["modelValue", "min-date", "focused-car", "additional-replacements"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[7] || (_cache[7] = _createTextVNode(" Vous n'avez pas encore renseigné de véhicule de remplacement")),
            _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
            _cache[9] || (_cache[9] = _createTextVNode(" pour pouvoir en fournir à votre client. ")),
            _createElementVNode("a", {
              href: `${$setup.calendarStore.baseUrl}/vehicules/remplacement/?from_calendar=true`,
              class: "replacement-empty-link"
            }, [
              (_openBlock(), _createElementBlock("svg", _hoisted_5, [
                _createElementVNode("use", {
                  href: $setup.svgLink('ui', 'long-arrow')
                }, null, 8 /* PROPS */, _hoisted_6)
              ])),
              _cache[6] || (_cache[6] = _createTextVNode(" Véhicule de remplacement "))
            ], 8 /* PROPS */, _hoisted_4)
          ]))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "onKeydown"]))
}