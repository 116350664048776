import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return ($setup.carObject($props.object).replacements.length === 0)
    ? _withDirectives((_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "replacement-car-button",
        onClick: $setup.openReplacementDialog
      }, [
        (_openBlock(), _createElementBlock("svg", {
          class: _normalizeClass(['replacement-car-icon', {'-finished': $setup.isReplacementFinished, '-affected': $setup.isReplacementAffected && !$setup.isReplacementFinished }])
        }, [
          _createElementVNode("use", {
            href: $setup.svgLink('cars', 'car-replacement')
          }, null, 8 /* PROPS */, _hoisted_1)
        ], 2 /* CLASS */))
      ])), [
        [
          _directive_tooltip,
          $setup.replacementTooltipOptions,
          void 0,
          { left: true }
        ]
      ])
    : (_openBlock(), _createBlock($setup["ReplacementSpeedDial"], {
        key: 1,
        car: $setup.carObject($props.object),
        replacement: $setup.carObject($props.object).replacements[0],
        "is-replacement-finished": $setup.isReplacementFinished,
        "is-replacement-affected": $setup.isReplacementAffected
      }, null, 8 /* PROPS */, ["car", "replacement", "is-replacement-finished", "is-replacement-affected"]))
}