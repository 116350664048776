<script setup>
import FloatLabel from "primevue/floatlabel"
import InputText from "primevue/inputtext"
import { useToast } from "primevue/usetoast"
import { ref } from "vue"

import { TOAST_DURATION } from "../../../../../ui/static_src/ui/utils/toast.js"
import { createInsurance } from "../../services/api.js"

const insuranceName = ref("")
const confirmedInsuranceName = ref("")
const showErrorMessage = ref(false)
const toast = useToast()
const loading = ref(false)

const props = defineProps({
  onCreation: {
    type: Function,
    required: true,
  },
})

const updateShowErrorMessage = () => {
  if (showErrorMessage.value) {
    showErrorMessage.value = insuranceName.value !== confirmedInsuranceName.value
  }
}

const handleCreateInsurance = async() => {
  if (showErrorMessage.value) return
  loading.value = true
  await createInsurance(insuranceName.value)
  toast.add({ severity: "success", summary: `L'assurance ${insuranceName.value} a bien été créée`, life: TOAST_DURATION })
  props.onCreation()
  loading.value = false
}
</script>

<template>
  <div class="insurance-create-container">
    <FloatLabel variant="on">
      <InputText
        id="insurance-name"
        v-model="insuranceName"
        name="insurance-name"
        style="min-width: 20rem;"
      />
      <label for="insurance-name">Nom de l'assurance</label>
    </FloatLabel>
    <FloatLabel variant="on">
      <InputText
        id="confirm-insurance-name"
        v-model="confirmedInsuranceName"
        name="confirm-insurance-name"
        style="min-width: 20rem;"
        @change="showErrorMessage = insuranceName !== confirmedInsuranceName"
        @update:model-value="updateShowErrorMessage()"
      />
      <label for="confirm-insurance-name">Confirmer le nom de l'assurance</label>
    </FloatLabel>
    <div
      v-if="showErrorMessage"
      class="insurance-create-error"
    >
      Les noms ne correspondent pas
    </div>
    <div class="insurance-create">
      <button
        class="insurance-create-button"
        :disabled="showErrorMessage"
        @click="handleCreateInsurance"
      >
        Créer
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.insurance-create-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: .4rem;
}

.insurance-create {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.insurance-create-button {
  @include body;
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: .4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--color-button-bg-submit-full-hover);
  }
}

.insurance-create-error {
  @include body;
  color: var(--color-text-warning);
}
</style>
