import { defineStore } from "pinia"

import { deleteAbsence, getStaff, getStaffList } from "../services/api.js"

const createBaseStaffModel = () => {
  return {
    id: null,
    first_name: null,
    last_name: null,
    domain: null,
    rank: null,
    company_id: window.STAFF_ENV.company_pk,
  }
}

const createBaseAbsenceModel = () => {
  return {
    id: null,
    staff_id: null,
    start_date: null,
    end_date: null,
    start_at: "morning",
    end_at: "afternoon",
    company_id: window.STAFF_ENV.company_pk,
  }
}

const createBaseContractModel = () => {
  return {
    id: null,
    company_id: window.STAFF_ENV.company_pk,
    staff_id: null,
    start_date: null,
    end_date: null,
    type: "cdi",
    weekly_hours: 35,
    week_days: {
      monday: { morning: true, afternoon: true },
      tuesday: { morning: true, afternoon: true },
      wednesday: { morning: true, afternoon: true },
      thursday: { morning: true, afternoon: true },
      friday: { morning: true, afternoon: true },
    },
  }
}

const useStaffStore = defineStore("staff", {
  state: () => ({
    staffList: [],
    focusedStaff: createBaseStaffModel(),
    focusedContract: createBaseContractModel(),
    focusedAbsence: createBaseAbsenceModel(),
    staffObject: null,
    isStaffModalOpen: false,
    isContractModalOpen: false,
    isAbsenceModalOpen: false,
    absenceModalSubmitCallback: async() => {},
    isListEmpty: false,
    loading: true,
    isHomepage: false,
  }),
  actions: {
    async init() {
      this.env = window.STAFF_ENV

      if (window.HOMEPAGE) {
        this.initHomepage()
        return
      }

      await this.fetchStaff()
    },
    async fetchStaff() {
      const { data } = await getStaffList()
      this.staffList = data
      this.isListEmpty = !data.length
      this.loading = false
    },
    initHomepage() {
      this.isHomepage = true
      this.staffList = window.HOMEPAGE_STAFF
      this.isListEmpty = false
      this.loading = false
    },
    openStaffModal(staff = null) {
      if (staff) Object.assign(this.focusedStaff, staff)
      else this.focusedStaff = createBaseStaffModel()
      this.isStaffModalOpen = true
    },
    openContractModal(staff, contract = null) {
      Object.assign(this.focusedStaff, staff)
      if (contract) Object.assign(this.focusedContract, contract)
      else this.focusedContract = createBaseContractModel()
      this.isContractModalOpen = true
    },
    initAbsenceModalSubmitCallback(fromList) {
      if (fromList) {
        this.absenceModalSubmitCallback = async() => (await this.fetchStaff())
      } else {
        this.absenceModalSubmitCallback = async() => {
          const { data } = await getStaff(this.staffObject.id)
          this.staffObject = data
        }
      }
    },
    async openAbsenceModal(staff, absence = null, fromList = false) {
      const { data } = await getStaff(staff.id)
      this.staffObject = data
      Object.assign(this.focusedStaff, staff)
      if (absence) Object.assign(this.focusedAbsence, absence)
      else this.focusedAbsence = createBaseAbsenceModel()
      this.initAbsenceModalSubmitCallback(fromList)
      this.isAbsenceModalOpen = true
    },
    async deleteAbsence(staffId, absenceId) {
      await deleteAbsence(absenceId)
      const { data } = await getStaff(staffId)
      this.staffObject = data
    },
  },
})

export default useStaffStore
