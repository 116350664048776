import { format, formatDistance } from "date-fns"
import { fr } from "date-fns/locale"

export const getCurrentMonday = (currentDate = null) => {
  const date = currentDate ? new Date(currentDate) : new Date()
  const day = date.getDay()
  const diff = date.getDate() - day + (day === 0 ? -6 : 1)
  const monday = new Date(date.setDate(diff))
  monday.setHours(0, 0, 0, 0)
  return monday
}

export const getIsoString = (date) => {
  return format(new Date(date), "yyyy-MM-dd")
}

export const getDatetimeString = (date) => {
  return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss")
}

export const getNextFriday = (date) => {
  const friday = new Date(date)
  friday.setDate(date.getDate() + (5 - date.getDay()))
  return friday
}

export const getFullDate = (date) => {
  return new Date(date).toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  })
}

export const getShortDateTime = (date) => {
  return new Date(date).toLocaleString("fr-FR", {
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  })
}

export const getShortTime = (date) => {
  return new Date(date).toLocaleString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
  })
}

export const getMonthDate = (date) => {
  const monthDate = new Date(date).toLocaleDateString("fr-FR", {
    month: "long",
    year: "numeric",
  })
  return monthDate.charAt(0).toUpperCase() + monthDate.slice(1)
}

export const getDateDayName = (date) => {
  const str = new Date(date).toLocaleDateString("fr-FR", {
    weekday: "long",
    day: "2-digit",
    month: "2-digit",
  })
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getShortDate = (date) => {
  return new Date(date).toLocaleDateString("fr-FR", {
    month: "short",
    day: "2-digit",
  })
}

export const humanizeDate = (date) => {
  return formatDistance(new Date(date), new Date(), {
    addSuffix: true, locale: fr,
  })
}

export const getNumericDate = (date) => {
  return new Date(date).toLocaleDateString("fr-FR", {
    month: "2-digit",
    day: "2-digit",
  })
}
