import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "insurance-groups"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return ($props.insuranceGroups.length > 0)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.insuranceGroups, (insuranceGroup) => {
          return (_openBlock(), _createElementBlock("div", {
            key: insuranceGroup.id,
            class: "car__card-state",
            style: _normalizeStyle(`background: ${insuranceGroup.color}`)
          }, null, 4 /* STYLE */))
        }), 128 /* KEYED_FRAGMENT */))
      ])), [
        [
          _directive_tooltip,
          $setup.insuranceGroupTooltip,
          void 0,
          { right: true }
        ]
      ])
    : _createCommentVNode("v-if", true)
}