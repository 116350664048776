<script setup>
import { useDebounceFn } from "@vueuse/core"
import { CircleX, Clock, Flame, Inbox, Search } from "lucide-vue-next"
import { storeToRefs } from "pinia"
import { DatePicker, FloatLabel } from "primevue"
import AutoComplete from "primevue/autocomplete"
import { computed, onMounted, ref, watch } from "vue"

import CalendarCarFollowUp from "./CalendarCarFollowUp.vue"

import { getFullDate, getIsoString, getMonthDate } from "../../../../../ui/static_src/ui/utils/date.js"
import { svgLink } from "../../../../../ui/static_src/ui/utils/svg.js"
import { serializeCar } from "../../serializers/car.js"
import { getCarSearch } from "../../services/api.js"
import { useCalendarStore } from "../../stores/calendar.js"
import { getCalendarDate } from "../../utils/cars.js"

const calendarStore = useCalendarStore()
const { currentWeekVisible, notificationsCount } = storeToRefs(calendarStore)
const autoCompleteRef = ref(null)
const query = ref("")
const searchResults = ref([])

const currentMonth = computed(() => {
  return getMonthDate(currentWeekVisible.value)
})

const isReplacementFinished = (car) => {
  if (car.replacements.length === 0) {
    return false
  }
  return car.replacements[0].finished && new Date(car.replacements[0].end_date).getTime() < new Date().getTime()
}

const handleChangeMonth = (date) => {
  const nextMonday = new Date(date)
  nextMonday.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7)
  calendarStore.currentWeekVisible = nextMonday
  window.location.href = `${calendarStore.baseUrl}/calendrier/?date=${getIsoString(nextMonday)}`
}

const handleSelect = (event) => {
  window.location.href = `${calendarStore.baseUrl}/calendrier/?date=${getIsoString(getCalendarDate({ car: event.value, type: "car" }))}&search=${event.value.id}`
}

const onFocus = () => {
  autoCompleteRef.value.show()
}

const searchNewResults = useDebounceFn(() => {
  getCarSearch(query.value || "e", calendarStore.env.company_pk).then(({ data }) => {
    searchResults.value = data.results.map(serializeCar)
  })
}, 500)

watch([query], () => searchNewResults(query.value))

onMounted(() => {
  if (window.CALENDAR_ENV.user !== "{}") {
    searchNewResults("e")
  }
})
</script>

<template>
  <div class="calendar-toolbar">
    <div class="calendar-toolbar-left">
      <FloatLabel
        variant="on"
        class="toolbar"
      >
        <AutoComplete
          id="car_in_charge_in_calendar"
          ref="autoCompleteRef"
          v-model="query"
          style="width: 15rem;"
          :suggestions="searchResults"
          :option-label="option => option.owner_last_name + ' | ' + option.model"
          @focus="onFocus"
          @item-select="handleSelect"
        >
          <template #option="slotProps">
            <div class="calendar-car-search">
              <div :class="`car__card-state -${slotProps.option.state}`" />
              <div class="calendar-week-day-car-name">
                <span class="car-name">{{ slotProps.option.owner_last_name }} | {{ slotProps.option.model }}</span>
              </div>
              <div class="calendar-week-day-car-slot">
                <span v-if="slotProps.option.is_seriously_damaged">
                  <Flame
                    size="18"
                    class="seriously-damaged-icon"
                  />
                </span>
                <span v-else-if="slotProps.option.from_tow_truck">
                  <svg class="tow-truck-icon">
                    <use :href="svgLink('cars', 'tow-truck')" />
                  </svg>
                </span>
              </div>
              <div class="calendar-week-day-car-slot">
                <svg
                  v-if="slotProps.option.need_replacement"
                  :class="['replacement-car-icon', {'-finished': isReplacementFinished(slotProps.option)}]"
                >
                  <use :href="svgLink('cars', 'car-replacement')" />
                </svg>
              </div>
              <CalendarCarFollowUp :car="slotProps.option" />
              <div class="car-calendar-date">
                {{ getFullDate(getCalendarDate({type: "car", car:slotProps.option})) }}
              </div>
            </div>
          </template>
          <template #empty>
            <div class="car-search-empty">
              Aucun véhicule ne correspond à votre recherche
            </div>
          </template>
        </AutoComplete>
        <label for="car_in_charge_in_calendar">
          <Search size="14" />
          Rechercher un véhicule
        </label>
      </FloatLabel>
    </div>
    <div class="calendar-toolbar-right">
      <DatePicker
        v-model="currentMonth"
        view="month"
        style="width: 10rem;"
        show-icon
        @update:model-value="handleChangeMonth"
      />
      <div class="calendar-buttons">
        <button
          v-tooltip.left="{ value: 'Notifications', showDelay: 500 }"
          class="-highlighted calendar-button"
          @click="calendarStore.notificationsModalOpen = true"
        >
          <Inbox size="24" />
          <div
            v-if="notificationsCount > 0"
            class="notification-count"
          >
            {{ notificationsCount }}
          </div>
        </button>
        <button
          v-if="calendarStore.currentUser?.is_admin"
          v-tooltip.left="{ value: 'Prévoir une fermeture de votre carrosserie', showDelay: 500 }"
          class="-highlighted calendar-button"
          @click="calendarStore.openClosureModal()"
        >
          <CircleX size="24" />
        </button>
        <a
          v-tooltip.left="{ value: 'Aller à aujourd\'hui', showDelay: 500 }"
          class="-highlighted calendar-button"
          :href="`${calendarStore.baseUrl}/calendrier/`"
        >
          <Clock size="24" />
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.calendar-toolbar {
  display: flex;
  padding: 1rem;
  border-bottom: 0.4px solid var(--color-border-primary);
}

.calendar-toolbar-left,
.calendar-toolbar-right {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  width: calc(50% - 5rem);
}

.calendar-toolbar-right {
  justify-content: space-between;
  align-items: center;
  width: calc(50% + 5rem);
}

.calendar-buttons {
  display: flex;
  gap: 1rem;
}

.calendar-button {
  @include body;
  color: var(--color-text-quaternary);
  padding: .5rem;
}

.calendar-car-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 1.6rem;
  gap: .4rem;
}

.calendar-week-day-car-name {
  @include body;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.car-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 15rem;
}

.calendar-week-day-car-slot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 1.6rem;
  min-width: 1.6rem;
}

.replacement-car-icon {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-text-submit);

  &.-finished {
    color: var(--color-text-success);
  }
}

.car-search-empty {
  @include body;
}

.seriously-damaged-icon {
  color: var(--color-text-warning);
}

.tow-truck-icon {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-icon);
}

.car-calendar-date {
  width: 4.2rem;
  @include body;
}

.notification-count {
  position: absolute;
  @include body;
  color: var(--color-text-quaternary);
  background-color: var(--color-text-warning);
  border-radius: 50%;
  transform: translateX(70%) translateY(-60%);
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
