import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.calendarStore.insuranceModalOpen,
    "onUpdate:visible": _cache[0] || (_cache[0] = $event => (($setup.calendarStore.insuranceModalOpen) = $event)),
    header: "Créer une assurance",
    onKeydown: $setup.handleKeyDown
  }, {
    default: _withCtx(() => [
      _createVNode($setup["InsuranceForm"], { "on-creation": $setup.handleCreateInsurance })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}