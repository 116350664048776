import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "insurance-group-tooltip" }
const _hoisted_2 = { class: "group-name" }
const _hoisted_3 = {
  key: 0,
  class: "group-insurances"
}
const _hoisted_4 = { class: "insurance-name" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.insuranceGroups, (insuranceGroup) => {
      return (_openBlock(), _createElementBlock("div", {
        key: insuranceGroup.id,
        class: "insurance-group"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "car__card-state",
            style: _normalizeStyle(`background: ${insuranceGroup.color}`)
          }, null, 4 /* STYLE */),
          _createTextVNode(" " + _toDisplayString(insuranceGroup.name), 1 /* TEXT */)
        ]),
        (insuranceGroup.insurances.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(insuranceGroup.insurances, (insurance) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: insurance.id,
                  class: _normalizeClass(['insurance', { '-highlighted': $props.highlightedInsurances.includes(insurance.id) }])
                }, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(insurance.name), 1 /* TEXT */)
                ], 2 /* CLASS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}