// --- Server API ---

export const getInsurancesGroups = async() => {
  return await api("GET", `/api/insurance-groups/?company_pk=${window.INSURANCES_ENV?.company_pk || window.CALENDAR_ENV?.company_pk}`)
}

export const getAggregatedReplacementCars = async(body) => {
  return await api(
    "POST",
    `/api/insurance-groups/aggregate/?company_pk=${window.INSURANCES_ENV.company_pk}`,
    body,
  )
}

export const createInsurance = async(name) => {
  return await api("POST", "/api/insurances/", { name })
}

export const getInsurances = async(name, excludeList) => {
  let url = `/api/insurances/?query=${name}`
  if (excludeList) {
    excludeList.forEach(pk => {
      url += `&exclude=${pk}`
    })
  }
  return await api("GET", url)
}

export const createInsuranceGroup = async(body) => {
  return await api("POST", `/api/insurance-groups/?company_pk=${window.INSURANCES_ENV.company_pk}`, body)
}

export const deleteInsurancesGroup = async(id) => {
  return await api("DELETE", `/api/insurance-groups/${id}/?company_pk=${window.INSURANCES_ENV.company_pk}`)
}

export const updateInsuranceGroup = async(body) => {
  return await api("PATCH", `/api/insurance-groups/${body.id}/?company_pk=${window.INSURANCES_ENV.company_pk}`, body)
}

// --- Internals ---

const api = async(method, path, body) => {
  body = body || {}
  let data
  // eslint-disable-next-line compat/compat
  const response = await fetch(path, {
    method: method || "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": window.CSRF_TOKEN,
    },
    body: method === "GET" ? undefined : JSON.stringify(body),
  })
  if (response.ok && !path.includes("format=")) {
    try {
      data = await response.json()
    } catch (e) {
      // No-op
    }
  }
  return { response, data }
}
