import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "closure-form-block" }
const _hoisted_2 = { class: "closure-form-validate" }
const _hoisted_3 = {
  class: "closure-form-button",
  type: "submit"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.closureModalOpen,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => (($setup.closureModalOpen) = $event)),
    header: $setup.focusedClosure?.id ? 'Modifier la fermeture' : 'Prévoir une fermeture',
    modal: ""
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Form"], {
        class: "closure-form",
        resolver: $setup.resolver,
        onSubmit: $setup.onFormSubmit
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["FloatLabel"], { variant: "on" }, {
              default: _withCtx(() => [
                _createVNode($setup["DatePicker"], {
                  id: "start_date",
                  modelValue: $setup.focusedClosure.start_date,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.focusedClosure.start_date) = $event)),
                  name: "start_date",
                  style: {"width":"12.5rem"},
                  "date-format": "dd/mm/yy"
                }, null, 8 /* PROPS */, ["modelValue"]),
                _cache[3] || (_cache[3] = _createElementVNode("label", { for: "start_date" }, "Date de début", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["FloatLabel"], { variant: "on" }, {
              default: _withCtx(() => [
                _createVNode($setup["DatePicker"], {
                  id: "end_date",
                  modelValue: $setup.focusedClosure.end_date,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.focusedClosure.end_date) = $event)),
                  name: "end_date",
                  "date-format": "dd/mm/yy",
                  style: {"width":"12.5rem"}
                }, null, 8 /* PROPS */, ["modelValue"]),
                _cache[4] || (_cache[4] = _createElementVNode("label", { for: "end_date" }, "Date de fin", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", _hoisted_3, _toDisplayString($setup.focusedClosure?.id ? 'Modifier' : 'Ajouter'), 1 /* TEXT */)
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "header"]))
}