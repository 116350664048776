<script setup>
import { asyncComputed } from "@vueuse/core/index.cjs"
import { storeToRefs } from "pinia"
import { useToast } from "primevue"
import Dialog from "primevue/dialog"
import { h, ref } from "vue"
import { renderToString } from "vue/server-renderer"

import ReplacementForm from "../../../../../../cars/static_src/cars/components/_partials/ReplacementForm.vue"
import CarDetails from "../../../../../../cars/static_src/cars/components/tooltips/CarDetails.vue"

import { getDatetimeString, getNextFriday, getShortDate } from "../../../../../../ui/static_src/ui/utils/date.js"
import { svgLink } from "../../../../../../ui/static_src/ui/utils/svg.js"
import { TOAST_DURATION } from "../../../../../../ui/static_src/ui/utils/toast.js"
import { createReplacement, updateReplacement } from "../../../services/api.js"
import { useCalendarStore } from "../../../stores/calendar.js"
import { useCarStore } from "../../../stores/car.js"
import { getCalendarDate } from "../../../utils/cars.js"

const calendarStore = useCalendarStore()
const carStore = useCarStore()
const toast = useToast()
const { focusedCar } = storeToRefs(carStore)

const replacement = ref({ start_date: "", end_date: "", replacement_car: null, finished: false, collapsing_replacements: [] })
const minDate = ref(null)

const refreshReplacement = () => {
  minDate.value = getCalendarDate({ type: "car", car: focusedCar.value })
  const startDate = new Date(minDate.value)
  startDate.setHours(8)
  const endDate = getNextFriday(minDate.value)
  endDate.setHours(17)
  replacement.value = focusedCar.value.replacements.length === 0
    ? {
        start_date: startDate,
        end_date: endDate,
        replacement_car: null,
        finished: false,
        collapsing_replacements: [],
      }
    : { ...focusedCar.value.replacements[0], start_date: new Date(focusedCar.value.replacements[0].start_date), end_date: new Date(focusedCar.value.replacements[0].end_date) }
}

const handleValidate = async() => {
  if (!replacement.value.start_date || !replacement.value.end_date || !replacement.value.replacement_car) return

  if (replacement.value.isSuggestion) {
    calendarStore.updateSuggestedReplacement(replacement.value)
    calendarStore.replacementModalOpen = false
    return
  }

  calendarStore.replacementModalOpen = false
  const delayedWorks = calendarStore.delayedWorks.filter(work => work.car.id === focusedCar.value.id)
  delayedWorks.forEach(work => {
    calendarStore.delayedWorks.find(w => w.id === work.id).car.replacements = [replacement.value]
  })

  const obj = {
    ...replacement.value,
    start_date: getDatetimeString(replacement.value.start_date),
    end_date: getDatetimeString(replacement.value.end_date),
    replacement_car_id: replacement.value.replacement_car.id,
    car_incharge_id: focusedCar.value.id,
    company_id: window.CALENDAR_ENV.company_pk,
  }
  if (!replacement.value.id) await createReplacement(obj)
  else await updateReplacement(obj)
  toast.add({
    severity: "success",
    summary: "Prêt enregistré",
    detail: `Le véhicule de remplacement ${replacement.value.replacement_car.registration} - ${replacement.value.replacement_car.brand} - ${replacement.value.replacement_car.model} a été enregistré pour ${focusedCar.value.owner_last_name} | ${focusedCar.value.model} du ${getShortDate(replacement.value.start_date)} au ${getShortDate(replacement.value.end_date)}`,
    life: TOAST_DURATION,
  })
  await calendarStore.updateCarInCharge(focusedCar.value)
}

const carNameTooltipOptions = asyncComputed(async() => {
  if (!focusedCar.value?.id) return { escape: false, value: "" }
  const htmlContent = await renderToString(h(CarDetails, { object: { car: focusedCar.value, type: "car" } }))
  return { escape: false, value: htmlContent }
})

const handleEscape = (event) => {
  calendarStore.replacementModalOpen = false

  event.preventDefault()
  event.stopPropagation()
}
</script>

<template>
  <Dialog
    v-model:visible="calendarStore.replacementModalOpen"
    modal
    class="replacement"
    @show="refreshReplacement"
    @keydown.esc.stop="handleEscape"
  >
    <template #header>
      <div
        v-if="!replacement.id"
        class="replacement-header"
      >
        <span>Fournir un véhicule de remplacement pour</span><br>
        <div v-tooltip.right="carNameTooltipOptions">
          {{ focusedCar.owner_last_name }} | {{ focusedCar.model }}
        </div>
      </div>
      <div
        v-else
        class="replacement-header"
      >
        <span>Modifier l'emprunt pour</span><br>
        <div v-tooltip.right="carNameTooltipOptions">
          {{ focusedCar.owner_last_name }} | {{ focusedCar.model }}
        </div>
      </div>
    </template>
    <ReplacementForm
      v-if="calendarStore.replacementCars.length > 0"
      v-model="replacement"
      :handle-validate="handleValidate"
      :min-date="minDate"
      :focused-car="focusedCar"
      :additional-replacements="calendarStore.suggestedReplacements"
    />
    <div
      v-else
      class="replacement-empty"
    >
      Vous n'avez pas encore renseigné de véhicule de remplacement<br>
      pour pouvoir en fournir à votre client.
      <a
        :href="`${calendarStore.baseUrl}/vehicules/remplacement/?from_calendar=true`"
        class="replacement-empty-link"
      >
        <svg class="replacement-empty-icon">
          <use :href="svgLink('ui', 'long-arrow')" />
        </svg>
        Véhicule de remplacement
      </a>
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.replacement-header {
  @include subtitle;

  & span {
    color: var(--color-text-secondary);
  }
}

.replacement-empty {
  @include body;
}

.replacement-empty-link {
  @include body;
  background-color: var(--color-button-bg-submit);
  color: var(--color-text-quaternary);
  padding: .4rem .8rem;
  border-radius: var(--radius);
  cursor: pointer;
  z-index: 2;
  opacity: 1;
  margin-top: .4rem;
  width: fit-content;

  &:hover {
      background-color: var(--color-button-bg-submit-full-hover);
      color: var(--color-text-quaternary);
      opacity: 1;
  }
}

.replacement-empty-icon {
  width: 1.2rem;
  height: 1.2rem;
}
</style>
