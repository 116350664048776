import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "replacement-car-name" }
const _hoisted_2 = { class: "replacement-car-date" }
const _hoisted_3 = {
  key: 0,
  class: "replacement-car-marker-date"
}
const _hoisted_4 = {
  key: 1,
  class: "replacement-car-marker-date -end"
}
const _hoisted_5 = {
  key: 2,
  class: "replacement-car-marker"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.replacement.replacement_car.insurance_groups, (insuranceGroup) => {
        return (_openBlock(), _createElementBlock("div", {
          key: insuranceGroup.id,
          class: "car__card-state",
          style: _normalizeStyle(`background: ${insuranceGroup.color}`)
        }, null, 4 /* STYLE */))
      }), 128 /* KEYED_FRAGMENT */)),
      _createTextVNode(" " + _toDisplayString($setup.carName), 1 /* TEXT */)
    ]),
    _createVNode($setup["Timeline"], {
      value: $setup.timeline,
      layout: "horizontal",
      class: "replacement-car-timeline"
    }, {
      content: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_2, _toDisplayString($props.withTime ? $setup.getShortDateTime(new Date(slotProps.item.date)) : $setup.getFullDate(new Date(slotProps.item.date))), 1 /* TEXT */)
      ]),
      opposite: _withCtx((slotProps) => [
        (slotProps.item.label === 'end')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['label-text', $setup.isReplacementFinished ? '-finished' : '-waiting' ])
            }, _toDisplayString($setup.isReplacementFinished ? 'Véhicule rendu' : 'Véhicule non rendu'), 3 /* TEXT, CLASS */))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(['label-text', '-start', $setup.isBeforeToday(slotProps.item.date) ? '-finished' : '-waiting'])
            }, _toDisplayString($setup.isBeforeToday(slotProps.item.date) ? 'Véhicule emprunté' : 'Véhicule prévu'), 3 /* TEXT, CLASS */))
      ]),
      marker: _withCtx((slotProps) => [
        ($setup.isBeforeToday(slotProps.item.date) && slotProps.item.label === 'start')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode($setup["Check"], {
                size: "14",
                class: "replacement-car-icon"
              })
            ]))
          : ($setup.isReplacementFinished && slotProps.item.label === 'end')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode($setup["Check"], {
                  size: "14",
                  class: "replacement-car-icon"
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["value"])
  ], 64 /* STABLE_FRAGMENT */))
}