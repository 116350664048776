import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "car" }
const _hoisted_2 = { class: "car-text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($props.carInCharge.owner_last_name) + _toDisplayString(!$props.onlyOwnerLastName ? ' | ' + $props.carInCharge.model : ''), 1 /* TEXT */)
  ])), [
    [
      _directive_tooltip,
      $setup.carNameTooltipOptions,
      void 0,
      { bottom: true }
    ]
  ])
}