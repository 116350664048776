import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "week-replacement-suggestions" }
const _hoisted_2 = { class: "week-day-name" }
const _hoisted_3 = {
  key: 0,
  class: "week-day-cars"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "car-details" }
const _hoisted_6 = {
  key: 1,
  class: "week-day-empty"
}
const _hoisted_7 = { class: "week-replacements-suggestions-buttons" }
const _hoisted_8 = {
  key: 0,
  class: "loading-data"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.days, (day) => {
        return (_openBlock(), _createElementBlock("div", {
          key: day,
          class: "week-day"
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString($setup.getDateDayName(day)), 1 /* TEXT */),
          ($setup.carsPerDay[$setup.getIsoString(day)]?.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.carsPerDay[$setup.getIsoString(day)], ({car, replacement}) => {
                  return (_openBlock(), _createElementBlock("button", {
                    key: car.id,
                    class: _normalizeClass({
            'week-day-car': true,
            '-replacement': replacement !== undefined && replacement.replacement_car,
            '-no-hover': replacement && replacement.replacement_car,
          }),
                    onClick: $event => (replacement && replacement.replacement_car ? null : $props.openReplacementDialog(car))
                  }, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode($setup["CarName"], { "car-in-charge": car }, null, 8 /* PROPS */, ["car-in-charge"]),
                      _createVNode($setup["InsuranceGroups"], {
                        "insurance-groups": $setup.getCarMatchingInsuranceGroups(car),
                        "highlighted-insurances": car.insurances.map(i => i.id)
                      }, null, 8 /* PROPS */, ["insurance-groups", "highlighted-insurances"])
                    ]),
                    (replacement && replacement.replacement_car)
                      ? (_openBlock(), _createBlock($setup["SuggestedReplacement"], {
                          key: 0,
                          replacement: replacement,
                          onDeleteReplacement: $setup.deleteReplacement,
                          onUpdateReplacement: $props.openReplacementDialog
                        }, null, 8 /* PROPS */, ["replacement", "onUpdateReplacement"]))
                      : _createCommentVNode("v-if", true)
                  ], 10 /* CLASS, PROPS */, _hoisted_4))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, " Aucun client en attente d'un VR "))
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createElementVNode("div", _hoisted_7, [
      ($setup.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[0] || (_cache[0] = [
            _createElementVNode("i", { class: "pi pi-spin pi-cog loading-icon" }, null, -1 /* HOISTED */)
          ])))
        : (_openBlock(), _createBlock(_TransitionGroup, {
            key: 1,
            name: "list",
            class: "week-replacements-suggestions-buttons",
            tag: "div"
          }, {
            default: _withCtx(() => [
              ($setup.replacements.length !== $props.carsInCharge.length)
                ? (_openBlock(), _createElementBlock("button", {
                    key: "get-replacements-suggestions",
                    class: "submit",
                    onClick: $setup.getReplacementsSuggestions
                  }, " Obtenir des suggestions "))
                : _createCommentVNode("v-if", true),
              ($setup.replacements.length > 0)
                ? (_openBlock(), _createElementBlock("button", {
                    key: "reset-replacements",
                    class: "submit",
                    onClick: $setup.reset
                  }, " Réinitialiser "))
                : _createCommentVNode("v-if", true),
              ($setup.replacements.length > 0)
                ? (_openBlock(), _createElementBlock("button", {
                    key: "create-replacements",
                    class: "submit",
                    onClick: $setup.bulkCreateReplacements
                  }, " Créer les remplacements "))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }))
    ])
  ], 64 /* STABLE_FRAGMENT */))
}