import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "replacement-car-tooltpip"
}
const _hoisted_2 = {
  key: 1,
  class: "replacement-car-tooltpip"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$setup.props.replacement)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", null, "Prêter un véhicule de remplacement", -1 /* HOISTED */)
      ])))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode($setup["ReplacementTimeline"], {
          replacement: $setup.props.replacement
        }, null, 8 /* PROPS */, ["replacement"])
      ]))
}