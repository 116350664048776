<script setup>
import { asyncComputed } from "@vueuse/core/index.cjs"
import { h } from "vue"
import { renderToString } from "vue/server-renderer"

import InsuranceGroupTooltip from "../tooltips/InsuranceGroup.vue"

const props = defineProps({
  insuranceGroups: {
    type: Array,
    required: true,
  },
  highlightedInsurances: {
    type: Array,
    required: false,
    default: () => [],
  },
})

const insuranceGroupTooltip = asyncComputed(async() => {
  if (props.insuranceGroups.length === 0) return { escape: false, value: "" }
  const htmlContent = await renderToString(h(InsuranceGroupTooltip, {
    insuranceGroups: props.insuranceGroups,
    highlightedInsurances: props.highlightedInsurances,
  }))
  return { escape: false, value: htmlContent }
})
</script>

<template>
  <div
    v-if="insuranceGroups.length > 0"
    v-tooltip.right="insuranceGroupTooltip"
    class="insurance-groups"
  >
    <div
      v-for="insuranceGroup in insuranceGroups"
      :key="insuranceGroup.id"
      class="car__card-state"
      :style="`background: ${insuranceGroup.color}`"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.insurance-groups {
  display: flex;
  align-items: center;

  & > .car__card-state:not(:first-child) {
    margin-left: -.4rem;
  }
}
</style>
