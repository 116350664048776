<script setup>
import { asyncComputed } from "@vueuse/core/index.cjs"
import { h } from "vue"
import { renderToString } from "vue/server-renderer"

import CarDetailsTooltip from "../tooltips/CarDetails.vue"

const props = defineProps({
  carInCharge: {
    type: Object,
    required: true,
  },
  onlyOwnerLastName: {
    type: Boolean,
    default: false,
  },
})

const carNameTooltipOptions = asyncComputed(async() => {
  const htmlContent = await renderToString(h(CarDetailsTooltip, { object: { car: props.carInCharge, type: "car" } }))
  return { escape: false, value: htmlContent }
})
</script>

<template>
  <div
    v-tooltip.bottom="carNameTooltipOptions"
    class="car"
  >
    <span class="car-text">{{ carInCharge.owner_last_name }}{{ !onlyOwnerLastName ? ' | ' + carInCharge.model : '' }}</span>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.car {
  @include body;
  display: flex;
  width: 100%;
}

.car-text {
  max-width: 12rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
